import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080 1070">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M578.000000,1071.000000 
	C385.355621,1071.000000 193.211227,1071.000000 1.033417,1071.000000 
	C1.033417,714.396973 1.033417,357.793915 1.033417,1.095440 
	C360.894623,1.095440 720.789307,1.095440 1080.842041,1.095440 
	C1080.842041,357.666626 1080.842041,714.333313 1080.842041,1071.000000 
	C913.460144,1071.000000 745.980042,1071.000000 578.000000,1071.000000 
M483.230072,489.541290 
	C483.841858,489.833588 484.453674,490.125885 485.596649,490.861115 
	C486.751953,491.574738 487.907288,492.288330 489.167328,493.651764 
	C490.791382,494.652954 492.415466,495.654144 494.607147,497.158966 
	C495.000580,497.476959 495.394012,497.794983 495.599670,498.181427 
	C495.599670,498.181427 495.779633,498.102875 496.553986,498.533051 
	C496.823639,498.653503 497.093262,498.773926 497.376495,499.525391 
	C498.270630,499.776917 499.164734,500.028442 500.753204,500.754272 
	C509.201019,503.783173 512.118286,502.423584 514.755127,494.033234 
	C515.441956,491.847656 515.752625,489.543854 516.746643,486.996948 
	C516.819092,486.319305 516.891541,485.641632 517.359436,484.200989 
	C517.423523,483.070526 517.487671,481.940033 518.126221,480.471222 
	C517.833130,478.866333 517.540100,477.261475 517.279419,474.909882 
	C515.715393,472.296234 514.151428,469.682617 512.664551,466.490570 
	C512.093933,466.102142 511.523315,465.713715 510.570709,464.774811 
	C509.898743,464.211731 509.226776,463.648651 508.579956,462.434082 
	C506.029022,460.685486 503.478088,458.936890 500.893005,457.050476 
	C500.893005,457.050476 500.758881,457.097046 500.652039,456.452057 
	C494.715179,452.926361 488.901642,449.162018 482.778412,445.996857 
	C480.452179,444.794373 477.027771,444.032440 476.199158,448.420746 
	C474.696014,456.381226 473.129364,464.331879 471.787598,472.319946 
	C470.953888,477.283264 474.324310,483.213287 479.290283,486.316681 
	C479.784180,486.594971 480.278076,486.873230 480.970917,487.750641 
	C481.655273,488.135651 482.339630,488.520660 483.037659,488.984528 
	C483.037659,488.984528 483.114807,488.963318 483.230072,489.541290 
M339.002075,690.014343 
	C339.002075,690.014343 339.100586,689.764038 339.763031,689.787354 
	C341.478210,688.528076 343.193390,687.268860 345.632751,685.808838 
	C346.414093,685.219055 347.195435,684.629211 348.435608,684.293823 
	C348.852448,683.843506 349.269257,683.393127 350.410095,682.643066 
	C350.595154,682.458740 350.780212,682.274414 351.430847,682.338501 
	C351.877808,681.879211 352.324768,681.419922 353.448578,680.594360 
	C353.624969,680.408081 353.801361,680.221802 354.431580,680.294983 
	C354.838501,679.834351 355.245392,679.373718 356.324982,678.719238 
	C358.578827,675.830566 360.832642,672.941895 363.599365,669.869263 
	C363.713470,668.930481 363.827545,667.991699 364.180511,666.249756 
	C364.091705,665.146667 364.002869,664.043518 364.301056,662.449219 
	C362.528687,659.344604 360.756287,656.239990 358.860962,652.478821 
	C358.400726,652.027405 357.940460,651.575989 357.521942,650.550720 
	C356.987274,650.103943 356.452606,649.657166 355.891571,649.042419 
	C355.891571,649.042419 355.722168,649.056824 355.683014,648.372131 
	C353.064026,645.226990 350.750549,641.748779 347.761475,639.007141 
	C341.791962,633.531738 333.538361,633.244812 326.563232,637.518799 
	C315.990509,643.997070 305.342621,650.352783 294.719086,656.748047 
	C290.512146,659.280640 287.385620,662.566589 287.631348,667.843872 
	C287.899536,673.602905 289.701965,678.803711 294.279785,683.465759 
	C294.657715,683.963928 295.035645,684.462036 295.363068,685.609131 
	C296.573822,687.028503 297.784546,688.447937 299.196686,690.496094 
	C299.351715,690.652222 299.506744,690.808411 299.544312,691.571472 
	C301.379364,693.150513 303.214417,694.729492 305.577423,696.777100 
	C305.806763,696.829712 306.036072,696.882324 306.366760,697.451172 
	C306.908112,697.582764 307.449432,697.714355 308.592377,698.412842 
	C309.377472,698.636841 310.162567,698.860840 311.311493,699.705505 
	C317.786407,701.626709 323.554169,700.350220 328.879059,695.806030 
	C328.879059,695.806030 328.976562,695.550476 329.587311,695.642456 
	C330.250763,695.063416 330.914215,694.484375 332.336670,693.704346 
	C333.217194,693.161133 334.097717,692.617859 335.448029,692.328003 
	C335.908234,691.894287 336.368439,691.460510 337.546600,690.834351 
	C337.759338,690.707275 337.972046,690.580261 338.480377,690.606812 
	C338.755646,690.526428 338.842804,690.351685 339.002075,690.014343 
M337.152893,481.145569 
	C337.152893,481.145569 337.158905,481.204102 336.440613,481.231262 
	C331.623413,486.497284 331.837952,491.151276 337.299896,494.361725 
	C337.513794,494.609680 337.770020,494.800812 338.054352,495.663452 
	C338.175140,496.139954 338.153687,496.913605 338.437531,497.049438 
	C345.335754,500.351654 352.029327,504.350372 359.264984,506.595428 
	C365.270386,508.458771 373.730560,502.365326 374.925659,496.923553 
	C374.925659,496.923553 374.891174,496.867737 375.592194,496.852570 
	C378.628784,492.493774 381.665375,488.134979 384.850983,483.377869 
	C384.850983,483.377869 385.003571,482.980896 385.645142,482.865845 
	C385.998749,482.174225 386.352386,481.482605 386.857330,480.393158 
	C386.857330,480.393158 387.001312,479.992554 387.693085,479.910553 
	C389.019226,477.530762 390.345398,475.150970 391.892731,472.064850 
	C392.021423,470.160248 392.150146,468.255615 392.603943,465.675964 
	C390.650757,459.101776 384.174713,460.209351 379.525787,457.590149 
	C383.370178,456.895721 387.158997,456.961060 390.944550,456.877960 
	C395.006958,456.788788 398.805328,456.006622 401.788574,451.896820 
	C406.687439,448.870972 405.586639,443.379486 406.660553,438.988037 
	C408.140717,432.935272 405.849854,430.119659 399.623016,428.855865 
	C389.201263,426.740631 379.435577,430.336334 369.398773,432.257660 
	C359.882385,434.079376 354.440430,438.552124 354.499512,448.509979 
	C354.508636,450.049805 355.394531,451.584412 356.067993,453.819214 
	C356.776215,455.184418 357.484436,456.549622 358.342285,458.203278 
	C355.525269,459.152344 352.895966,459.467163 351.041504,460.833527 
	C349.393707,462.047607 348.605316,464.428040 347.175323,466.879364 
	C346.469421,467.940674 345.763489,469.001984 344.366455,470.131226 
	C341.981537,473.803162 339.596649,477.475098 337.152893,481.145569 
M314.365326,457.089966 
	C313.282654,458.093658 312.199982,459.097351 310.702698,460.269623 
	C310.702698,460.269623 310.280823,460.419128 309.579193,460.240479 
	C308.746185,461.181824 307.913147,462.123169 306.490448,463.248779 
	C305.721893,463.924438 304.953339,464.600128 303.469543,465.205139 
	C299.955414,470.768738 296.441315,476.332367 292.929077,481.920715 
	C292.929077,481.920715 292.911011,481.919250 292.212982,481.951050 
	C291.557037,482.695801 290.901062,483.440552 290.081177,484.567047 
	C290.081177,484.567047 289.894287,484.938171 289.192566,485.112396 
	C287.771606,485.781738 286.393921,486.571930 284.921997,487.098755 
	C279.544739,489.023407 273.995422,490.677216 271.540314,497.379974 
	C270.745392,498.312134 269.950500,499.244324 268.468811,500.168579 
	C268.110016,500.871429 267.751221,501.574249 267.233063,502.667297 
	C267.233063,502.667297 267.052673,503.048248 266.389069,503.119232 
	C266.018951,503.819763 265.648834,504.520294 265.130035,505.603790 
	C265.130035,505.603790 264.992462,505.990906 264.336395,506.103027 
	C264.005219,506.818695 263.674042,507.534393 263.210114,508.657135 
	C263.210114,508.657135 263.058258,509.057495 262.394958,509.133820 
	C262.054749,509.840393 261.714539,510.546967 261.126434,511.882965 
	C260.109558,513.614014 259.092682,515.345093 257.357727,517.128784 
	C255.304504,521.971313 252.432816,526.655945 251.458481,531.706604 
	C250.686798,535.706787 256.307129,538.115295 259.641876,535.408508 
	C261.897461,533.577759 263.545502,530.998413 265.407196,528.821777 
	C266.217316,529.188782 267.086548,529.582581 267.991852,530.006531 
	C267.991852,530.006531 268.018707,530.045349 268.125916,530.742981 
	C268.792847,531.122437 269.507538,531.896179 270.119568,531.822021 
	C279.139221,530.729614 287.725769,521.900024 288.857788,512.696472 
	C288.983551,511.674225 289.161041,510.658386 289.351776,509.392487 
	C295.562653,514.151367 295.999237,514.218384 302.096710,509.422943 
	C304.954865,507.175110 307.480072,504.509277 310.252106,502.145386 
	C315.252289,497.881348 317.849091,493.013306 314.099121,486.366577 
	C320.049469,485.265320 327.164093,487.189026 328.944763,478.947449 
	C328.944763,478.947449 328.942108,478.931763 329.642456,478.887268 
	C330.312958,477.840027 330.983459,476.792816 331.826172,475.374054 
	C331.826172,475.374054 332.022400,475.014679 332.698334,474.918182 
	C340.176514,466.880310 340.321167,465.918610 335.758759,458.267303 
	C336.175293,458.079010 336.591827,457.890717 337.249878,458.227783 
	C337.852478,457.842773 338.455078,457.457764 339.672699,456.909882 
	C340.734192,456.216431 341.795685,455.522949 343.534790,454.827026 
	C343.905090,454.134064 344.275391,453.441101 344.786438,452.338501 
	C344.786438,452.338501 344.931732,451.930450 345.568970,451.835144 
	C345.637543,450.892365 345.706116,449.949585 345.879272,448.259644 
	C345.894012,447.505554 345.908722,446.751495 346.411194,445.617035 
	C344.591278,441.545532 342.737823,441.338959 338.196259,444.952728 
	C337.131805,445.335022 336.067352,445.717316 334.600952,445.602356 
	C332.928467,446.089722 331.024567,446.220612 329.625336,447.128906 
	C324.710815,450.319244 319.969818,453.776978 315.115906,457.124908 
	C315.115906,457.124908 315.095428,457.168121 314.365326,457.089966 
M318.055634,593.333252 
	C318.055634,593.333252 317.740112,592.955994 317.785828,592.265625 
	C312.952881,581.869751 304.597229,579.692261 295.142426,585.975220 
	C288.398102,590.456970 281.168518,594.213989 274.475830,598.765442 
	C271.605560,600.717407 268.217102,603.489868 267.359375,606.535889 
	C264.200562,617.753784 267.110565,628.326416 273.038330,638.810364 
	C273.421509,639.534363 273.804657,640.258362 274.289215,641.646179 
	C278.731079,650.291077 289.359985,649.525513 299.666931,640.897888 
	C300.077362,640.668640 300.487762,640.439392 301.545685,640.447083 
	C305.268188,637.949707 308.990662,635.452332 313.397400,632.747498 
	C320.319183,628.202820 327.413300,623.780396 327.240601,613.537659 
	C327.003235,612.763245 326.765869,611.988770 326.620636,610.462830 
	C326.469849,609.990417 326.319031,609.518005 326.403137,608.379333 
	C323.655334,603.524170 320.907532,598.669067 318.055634,593.333252 
M239.100510,593.737305 
	C242.221191,596.810913 248.229462,598.075256 251.671738,595.962708 
	C255.699631,593.490784 256.864288,589.561462 256.199249,584.225525 
	C256.394958,582.148010 256.590668,580.070557 257.387939,577.420044 
	C257.913147,569.192505 258.489868,560.967834 258.940094,552.736206 
	C259.141418,549.054932 257.836884,545.839050 253.870880,545.349792 
	C249.845200,544.853149 245.161163,543.303040 242.978836,549.137634 
	C238.176605,561.976624 235.226578,575.115479 236.228470,588.890869 
	C236.332077,590.315552 238.039795,591.623535 239.002441,592.997559 
	C239.002441,592.997559 239.015121,592.996521 239.100510,593.737305 
M241.175583,639.313293 
	C241.394943,639.956665 241.614304,640.600037 241.787689,642.029236 
	C243.122299,648.405457 245.714401,653.712646 253.227798,655.756958 
	C256.994965,656.553467 260.910217,658.246277 264.551117,653.633545 
	C264.957733,649.394409 265.364349,645.155273 266.190735,640.479065 
	C265.201935,637.870850 264.213165,635.262695 263.262146,631.964844 
	C263.138672,631.287415 263.015228,630.610046 263.278534,629.434998 
	C262.651245,626.521973 262.139893,623.578125 261.375885,620.701416 
	C258.609894,610.286316 256.411224,608.427856 245.678970,607.137451 
	C245.185287,607.078125 244.696136,606.971802 244.209229,606.867126 
	C236.200668,605.143982 236.191696,605.143311 236.223114,613.213989 
	C236.230240,615.042664 236.049713,616.930298 236.428909,618.688782 
	C237.771286,624.914062 239.318726,631.095032 240.868149,638.058960 
	C241.147781,638.480103 241.427399,638.901306 241.175583,639.313293 
M384.954956,571.903015 
	C384.954956,571.903015 384.951782,571.763062 385.607727,571.680542 
	C387.314301,570.128418 389.254456,568.764954 390.687286,566.991638 
	C397.079834,559.079956 397.802643,551.207092 391.933990,544.278992 
	C388.000183,539.635071 383.320435,535.387756 378.294220,531.960693 
	C371.105835,527.059509 360.021545,529.010437 354.307617,534.773621 
	C346.141937,543.009705 337.895416,551.195435 329.253845,558.922180 
	C319.084595,568.014893 318.690918,583.364929 329.007599,592.590576 
	C329.007599,592.590576 329.305328,592.974670 329.275208,593.665466 
	C332.675781,597.042969 335.643860,601.099670 339.593567,603.614746 
	C343.895142,606.353882 349.269623,604.687073 353.444702,600.754395 
	C353.615997,600.632019 353.787323,600.509583 354.594086,600.548218 
	C355.837250,599.323914 357.080414,598.099670 358.921417,596.444031 
	C359.255463,596.084656 359.589508,595.725281 360.555573,595.512573 
	C365.564697,591.102173 370.648224,586.772034 375.540527,582.235657 
	C377.140045,580.752502 378.242096,578.732788 380.095215,576.540344 
	C380.375763,576.193542 380.656311,575.846741 381.571014,575.584290 
	C382.653259,574.366455 383.735535,573.148621 384.954956,571.903015 
M549.616699,549.221924 
	C547.205078,549.221924 544.793457,549.221924 542.412842,549.221924 
	C542.412842,587.115784 542.412842,624.200012 542.412842,661.559570 
	C549.467407,661.559570 556.196411,661.559570 563.563843,661.559570 
	C563.563843,639.554138 563.563843,617.796509 563.563843,596.038879 
	C564.838135,596.817810 565.187622,597.685913 565.417664,598.584473 
	C570.119568,616.949951 574.811584,635.317993 579.497009,653.687683 
	C581.695862,662.308655 581.687866,662.313721 590.611450,661.788879 
	C590.925720,661.770386 591.229248,661.570068 591.892700,661.321594 
	C597.486755,639.397461 603.134888,617.261414 608.783020,595.125305 
	C609.281494,595.218323 609.779968,595.311340 610.278442,595.404358 
	C610.278442,617.467712 610.278442,639.531067 610.278442,661.641113 
	C617.363831,661.641113 623.978149,661.641113 630.727600,661.641113 
	C630.727600,624.100281 630.727600,586.766846 630.727600,549.221924 
	C621.045776,549.221924 611.888977,549.434021 602.750671,549.116638 
	C599.135010,548.991089 597.741760,550.297546 597.008789,553.565308 
	C594.208618,566.048462 591.263062,578.499146 588.336426,590.953735 
	C587.812195,593.184509 587.120605,595.376099 586.506287,597.585815 
	C582.220703,582.424561 578.925659,567.534668 575.868042,552.596252 
	C575.310608,549.872803 574.204163,549.100525 571.577759,549.158142 
	C564.583435,549.311584 557.583740,549.217529 549.616699,549.221924 
M527.395813,636.902283 
	C524.477539,607.821106 521.559265,578.739929 518.632141,549.571289 
	C505.266327,549.571289 492.387665,549.571289 479.346497,549.571289 
	C475.330872,587.128723 471.352570,624.337219 467.376556,661.524109 
	C475.821442,661.524109 483.261597,661.524109 490.795319,661.524109 
	C491.412018,652.966614 492.007599,644.702576 492.621185,636.188477 
	C496.567383,636.188477 500.113800,636.188477 503.896667,636.188477 
	C504.555328,644.850098 505.191467,653.215271 505.819427,661.472900 
	C514.260681,661.472900 521.988098,661.472900 529.848816,661.472900 
	C529.074585,653.304382 528.339111,645.544983 527.395813,636.902283 
M648.835083,559.313538 
	C647.832397,563.496094 646.082520,567.656250 645.956726,571.864990 
	C645.419800,589.839355 645.138184,607.829407 645.241577,625.810974 
	C645.282471,632.922424 646.062866,640.124939 647.416382,647.109680 
	C649.119263,655.897156 654.430298,662.037170 663.831848,662.702087 
	C671.580566,663.250122 679.476257,663.403503 687.173340,662.540771 
	C695.473877,661.610352 701.594666,656.509033 702.964905,648.070862 
	C704.498596,638.625732 704.751526,628.972595 705.592285,619.051147 
	C697.362793,619.051147 690.317444,619.051147 683.345642,619.051147 
	C682.891663,624.201660 682.628967,629.011475 682.000244,633.773010 
	C681.432678,638.071960 678.235596,638.604797 674.571899,638.530334 
	C670.862000,638.455017 668.901306,636.760925 668.893799,633.113464 
	C668.854797,614.145813 668.856262,595.177979 668.908997,576.210327 
	C668.920715,572.014221 671.358704,570.403137 675.392395,570.442871 
	C679.589722,570.484192 681.933350,572.231934 682.086548,576.589478 
	C682.242981,581.038574 682.484070,585.484680 682.694580,590.072693 
	C690.112122,590.072693 697.164978,590.072693 704.685303,590.072693 
	C704.685303,585.741211 704.788086,581.762390 704.655884,577.791382 
	C704.528503,573.964050 704.429504,570.102600 703.842529,566.330078 
	C702.233582,555.987549 697.229675,549.795654 688.249878,548.802856 
	C680.052002,547.896667 671.677490,548.071167 663.412415,548.453064 
	C656.787659,548.759155 651.908325,552.455139 648.835083,559.313538 
M463.792908,580.611084 
	C463.508179,577.129272 463.395844,573.622986 462.907257,570.170044 
	C461.287140,558.720825 456.209229,551.300415 447.597504,550.537170 
	C433.591064,549.295593 419.477081,549.267273 405.153107,548.717102 
	C405.153107,586.985107 405.153107,624.206360 405.153107,661.570190 
	C412.853058,661.570190 420.258606,661.570190 428.199585,661.570190 
	C428.199585,650.390625 428.199585,639.475769 428.199585,627.848877 
	C433.008911,627.848877 437.307709,628.097412 441.568665,627.801575 
	C453.697815,626.959351 459.810577,622.256104 461.789307,610.348083 
	C463.357819,600.908936 463.200256,591.182922 463.792908,580.611084 
M834.067261,590.613464 
	C843.207092,613.433289 844.485168,637.324707 842.591553,661.611633 
	C851.658264,661.611633 859.587036,661.611633 868.038757,661.611633 
	C868.038757,649.945435 867.978638,638.503845 868.076416,627.063538 
	C868.104675,623.756042 868.094116,620.319580 868.944092,617.169556 
	C873.100098,601.767395 877.570435,586.450317 881.867859,571.085876 
	C883.850830,563.996216 885.692932,556.867126 887.742493,549.220154 
	C880.687683,549.220154 874.542908,549.459351 868.429199,549.127197 
	C864.953918,548.938477 863.755554,550.475464 863.110413,553.437195 
	C861.060547,562.847473 858.925659,572.239380 856.777954,581.627930 
	C856.482605,582.919006 855.929077,584.150940 855.023804,586.777649 
	C854.361328,584.379395 854.074463,583.489685 853.871033,582.581299 
	C851.654480,572.684814 849.530212,562.766174 847.152405,552.908752 
	C846.820801,551.533936 845.303467,549.448425 844.260193,549.402771 
	C837.159058,549.092285 830.037903,549.240295 822.140686,549.240295 
	C826.165100,563.146545 830.024841,576.483643 834.067261,590.613464 
M799.774536,573.093323 
	C804.344360,573.093323 808.914124,573.093323 813.366455,573.093323 
	C813.366455,564.646301 813.366455,557.092896 813.366455,549.598877 
	C793.726929,549.598877 774.508362,549.598877 755.341431,549.598877 
	C755.341431,557.567932 755.341431,565.145325 755.341431,573.210632 
	C761.264099,573.210632 766.841125,573.210632 772.847229,573.210632 
	C772.847229,602.948303 772.847229,632.189941 772.847229,661.599365 
	C780.708679,661.599365 788.123535,661.599365 796.081665,661.599365 
	C796.081665,658.428772 796.081726,655.475342 796.081726,652.521912 
	C796.081604,627.543213 796.066406,602.564575 796.111389,577.585938 
	C796.114868,575.667480 795.074402,572.852356 799.774536,573.093323 
M718.744934,607.499268 
	C718.744934,625.453491 718.744934,643.407715 718.744934,661.490112 
	C726.939270,661.490112 734.653381,661.490112 742.442566,661.490112 
	C742.442566,623.944214 742.442566,586.749023 742.442566,549.433411 
	C734.464966,549.433411 726.864075,549.433411 719.357727,549.433411 
	C719.122131,549.943542 718.864563,550.241577 718.863708,550.540405 
	C718.810791,569.193298 718.778809,587.846252 718.744934,607.499268 
M671.382507,722.014282 
	C674.497620,721.603394 677.689697,721.493835 680.712524,720.721741 
	C687.940735,718.875427 692.423157,713.204590 693.077942,705.613647 
	C693.841797,696.758362 690.634521,690.292114 683.558533,687.108948 
	C675.167969,683.334595 666.406067,685.715576 657.769165,684.917419 
	C657.769165,697.794312 657.769165,709.689575 657.769165,722.014771 
	C662.168213,722.014771 666.293579,722.014771 671.382507,722.014282 
M753.656677,722.007935 
	C755.920227,722.007935 758.183777,722.007935 760.952942,722.007935 
	C756.139221,710.188477 751.508057,698.992920 747.032715,687.735474 
	C745.425171,683.691833 742.092896,685.248474 739.354126,685.091370 
	C737.013428,684.957153 734.613953,684.389587 733.270569,687.768677 
	C729.037109,698.417175 724.386292,708.899475 719.937439,719.463135 
	C719.657959,720.126953 719.724548,720.936523 719.587646,722.011780 
	C721.969238,722.011780 723.976929,722.214417 725.921021,721.949890 
	C727.581238,721.723938 730.442810,721.188171 730.583008,720.399963 
	C731.558044,714.916260 735.562622,716.003601 739.081238,716.023254 
	C743.037781,716.045410 747.630005,714.689026 748.943420,720.706238 
	C749.090393,721.379761 751.453796,721.569519 753.656677,722.007935 
M842.540588,721.995789 
	C845.833679,722.262939 849.020691,722.409912 849.477417,717.844788 
	C849.530518,717.314087 850.227112,716.438599 850.658203,716.418152 
	C854.963074,716.214966 859.299500,715.878845 863.567078,716.256958 
	C864.621887,716.350464 865.341309,718.998779 866.440918,720.278320 
	C867.106201,721.052490 868.175537,721.875549 869.116211,721.943298 
	C872.069702,722.156128 875.048279,722.021729 878.766602,722.021729 
	C873.974609,710.358337 869.313293,699.388550 865.010864,688.279785 
	C863.921875,685.467834 862.324829,684.631653 859.776550,685.084106 
	C856.704956,685.629456 852.810059,683.126526 850.918274,688.138733 
	C847.927673,696.062256 844.389954,703.778870 841.102600,711.590942 
	C839.729370,714.854492 838.404602,718.138428 836.812317,722.010010 
	C839.038269,722.010010 840.351257,722.010010 842.540588,721.995789 
M325.624786,550.151917 
	C329.866211,546.175171 334.241608,542.329895 338.311188,538.184509 
	C342.348755,534.071655 346.565125,529.992859 349.816833,525.286743 
	C353.666595,519.715088 352.446655,513.518677 347.192444,509.298401 
	C343.067963,505.985535 338.672058,502.937347 334.109375,500.261505 
	C328.229431,496.813171 321.413940,498.336151 316.883698,503.285919 
	C313.548157,506.930298 310.212280,510.665161 306.386536,513.752441 
	C296.289673,521.900513 296.430695,529.895630 300.676514,539.770813 
	C301.529297,541.754333 302.304810,543.771179 303.105621,545.776855 
	C307.479095,556.730408 313.525543,558.622620 323.010468,551.999451 
	C323.690674,551.524475 324.354462,551.026062 325.624786,550.151917 
M425.749084,463.693787 
	C432.699402,465.913391 439.556915,468.507660 446.623474,470.258087 
	C455.502716,472.457458 461.092743,468.902893 463.367981,460.209259 
	C464.291992,456.678711 465.006989,453.059906 465.415375,449.436035 
	C465.998627,444.260345 464.509247,439.979492 459.493683,437.341675 
	C448.004486,431.299316 435.692200,429.177368 422.884308,429.581116 
	C419.904022,429.675079 417.629150,431.142395 417.199585,434.727600 
	C416.629395,439.486816 415.995209,444.294708 414.724640,448.898438 
	C413.341858,453.908630 415.226959,457.321869 418.936646,460.092224 
	C420.778320,461.467529 422.979919,462.360840 425.749084,463.693787 
M300.744385,564.000244 
	C302.020752,553.494385 296.561707,544.738220 293.048645,535.556030 
	C291.343018,531.097961 286.790253,530.469177 282.651062,533.040161 
	C278.155731,535.832397 273.553711,538.457275 269.121490,541.344360 
	C267.501892,542.399292 265.565155,543.734497 264.916199,545.389954 
	C260.131226,557.596497 258.859344,570.235901 261.700134,583.076172 
	C262.906342,588.528259 267.507324,590.833862 272.376312,588.188354 
	C279.941925,584.077515 287.414215,579.650085 294.319458,574.537476 
	C297.228027,572.384033 298.591522,568.143555 300.744385,564.000244 
M815.546875,686.880981 
	C814.895020,686.272278 814.249023,685.140564 813.590454,685.133118 
	C803.507751,685.020630 793.423523,685.054749 783.444336,685.054749 
	C783.444336,688.186707 783.444336,690.757507 783.444336,693.519165 
	C787.059509,693.715759 790.289307,693.891357 794.097656,694.098389 
	C794.097656,703.615234 794.097656,712.661743 794.097656,721.637573 
	C797.956726,721.637573 801.183228,721.637573 804.824463,721.637573 
	C804.824463,715.468445 804.820496,709.675476 804.825378,703.882507 
	C804.833618,694.247192 804.836243,694.251038 814.191772,693.583191 
	C814.479858,693.562622 814.748657,693.273193 815.562805,692.792358 
	C815.562805,691.316467 815.562805,689.529053 815.546875,686.880981 
M295.951691,707.301453 
	C295.504242,703.801331 296.011993,699.772888 294.424744,696.903198 
	C290.183868,689.236084 285.511200,681.694397 280.067108,674.853333 
	C277.262482,671.329102 272.772461,668.282043 268.461334,667.037170 
	C262.936554,665.441895 256.825104,665.878418 250.391144,665.396362 
	C251.003723,667.265869 251.421265,669.342896 252.337433,671.169800 
	C254.270035,675.023499 256.175568,678.944458 258.609711,682.481689 
	C263.773712,689.985779 268.806488,697.664062 274.763184,704.514282 
	C280.308533,710.891479 289.115631,711.780029 295.951691,707.301453 
M295.584564,717.908142 
	C293.192566,717.799805 290.800568,717.691467 288.408569,717.583130 
	C288.148560,718.141174 287.888519,718.699280 287.628479,719.257385 
	C296.962891,726.044189 306.163483,733.026245 315.680267,739.546875 
	C321.706573,743.675964 328.121521,747.477905 335.855194,747.156372 
	C338.175293,747.059937 340.456909,746.038513 342.756134,745.440796 
	C341.472900,743.100647 340.725281,740.102356 338.807739,738.541565 
	C332.633148,733.516052 326.128357,728.873230 319.559784,724.361084 
	C312.665985,719.625488 305.046417,717.027100 295.584564,717.908142 
M448.585541,708.133545 
	C448.964020,703.430908 447.045868,700.385681 442.203339,699.754944 
	C438.056641,699.214783 434.748932,701.595581 434.185883,705.429626 
	C433.595642,709.449036 434.715027,712.739563 438.873749,714.184875 
	C443.139923,715.667542 446.354462,713.808899 448.585541,708.133545 
M487.740143,714.311768 
	C491.468018,712.965942 493.941864,710.228149 492.858093,706.413208 
	C492.164948,703.973328 489.806976,701.068787 487.537628,700.290405 
	C485.460541,699.578003 481.470459,700.672180 480.199799,702.372559 
	C478.744324,704.320129 478.587982,708.014648 479.259644,710.586548 
	C480.175232,714.092468 483.566284,714.820007 487.740143,714.311768 
M567.466614,705.120300 
	C566.858032,709.590820 568.040222,713.248535 572.842651,714.289001 
	C576.740601,715.133484 580.212952,713.998291 581.589111,709.718811 
	C582.864990,705.751526 581.793213,702.427979 577.986633,700.437378 
	C574.184875,698.449341 570.441711,699.978577 567.466614,705.120300 
M536.989868,703.708191 
	C533.745117,699.710632 529.841492,698.620239 526.253906,700.709473 
	C523.200684,702.487366 521.942200,706.927551 523.634949,710.391907 
	C525.567505,714.347229 528.975891,715.208740 532.872620,714.149658 
	C536.731140,713.100769 538.194214,709.514587 536.989868,703.708191 
M625.078857,702.490662 
	C624.756958,702.115051 624.497070,701.649536 624.103333,701.378052 
	C620.536804,698.918640 616.833069,698.718750 613.671448,701.767029 
	C610.802734,704.532959 610.949646,708.168579 612.841736,711.406677 
	C614.941345,715.000061 618.565491,715.346497 622.031189,714.010132 
	C626.072449,712.451843 626.926147,708.938049 626.177979,704.951111 
	C626.058777,704.316101 625.712769,703.723572 625.078857,702.490662 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M550.101440,549.221924 
	C557.583740,549.217529 564.583435,549.311584 571.577759,549.158142 
	C574.204163,549.100525 575.310608,549.872803 575.868042,552.596252 
	C578.925659,567.534668 582.220703,582.424561 586.506287,597.585815 
	C587.120605,595.376099 587.812195,593.184509 588.336426,590.953735 
	C591.263062,578.499146 594.208618,566.048462 597.008789,553.565308 
	C597.741760,550.297546 599.135010,548.991089 602.750671,549.116638 
	C611.888977,549.434021 621.045776,549.221924 630.727600,549.221924 
	C630.727600,586.766846 630.727600,624.100281 630.727600,661.641113 
	C623.978149,661.641113 617.363831,661.641113 610.278442,661.641113 
	C610.278442,639.531067 610.278442,617.467712 610.278442,595.404358 
	C609.779968,595.311340 609.281494,595.218323 608.783020,595.125305 
	C603.134888,617.261414 597.486755,639.397461 591.892700,661.321594 
	C591.229248,661.570068 590.925720,661.770386 590.611450,661.788879 
	C581.687866,662.313721 581.695862,662.308655 579.497009,653.687683 
	C574.811584,635.317993 570.119568,616.949951 565.417664,598.584473 
	C565.187622,597.685913 564.838135,596.817810 563.563843,596.038879 
	C563.563843,617.796509 563.563843,639.554138 563.563843,661.559570 
	C556.196411,661.559570 549.467407,661.559570 542.412842,661.559570 
	C542.412842,624.200012 542.412842,587.115784 542.412842,549.221924 
	C544.793457,549.221924 547.205078,549.221924 550.101440,549.221924 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M527.499695,637.343994 
	C528.339111,645.544983 529.074585,653.304382 529.848816,661.472900 
	C521.988098,661.472900 514.260681,661.472900 505.819427,661.472900 
	C505.191467,653.215271 504.555328,644.850098 503.896667,636.188477 
	C500.113800,636.188477 496.567383,636.188477 492.621185,636.188477 
	C492.007599,644.702576 491.412018,652.966614 490.795319,661.524109 
	C483.261597,661.524109 475.821442,661.524109 467.376556,661.524109 
	C471.352570,624.337219 475.330872,587.128723 479.346497,549.571289 
	C492.387665,549.571289 505.266327,549.571289 518.632141,549.571289 
	C521.559265,578.739929 524.477539,607.821106 527.499695,637.343994 
M498.365417,613.104675 
	C499.776825,612.981689 501.188202,612.858765 502.827820,612.715942 
	C501.488892,599.191650 500.202026,586.193298 498.853577,572.572815 
	C497.754272,573.485046 497.348419,573.655212 497.328674,573.862122 
	C496.180542,585.914246 495.131561,597.976379 493.898834,610.019653 
	C493.581696,613.118164 495.225677,613.262390 498.365417,613.104675 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M648.967712,558.961914 
	C651.908325,552.455139 656.787659,548.759155 663.412415,548.453064 
	C671.677490,548.071167 680.052002,547.896667 688.249878,548.802856 
	C697.229675,549.795654 702.233582,555.987549 703.842529,566.330078 
	C704.429504,570.102600 704.528503,573.964050 704.655884,577.791382 
	C704.788086,581.762390 704.685303,585.741211 704.685303,590.072693 
	C697.164978,590.072693 690.112122,590.072693 682.694580,590.072693 
	C682.484070,585.484680 682.242981,581.038574 682.086548,576.589478 
	C681.933350,572.231934 679.589722,570.484192 675.392395,570.442871 
	C671.358704,570.403137 668.920715,572.014221 668.908997,576.210327 
	C668.856262,595.177979 668.854797,614.145813 668.893799,633.113464 
	C668.901306,636.760925 670.862000,638.455017 674.571899,638.530334 
	C678.235596,638.604797 681.432678,638.071960 682.000244,633.773010 
	C682.628967,629.011475 682.891663,624.201660 683.345642,619.051147 
	C690.317444,619.051147 697.362793,619.051147 705.592285,619.051147 
	C704.751526,628.972595 704.498596,638.625732 702.964905,648.070862 
	C701.594666,656.509033 695.473877,661.610352 687.173340,662.540771 
	C679.476257,663.403503 671.580566,663.250122 663.831848,662.702087 
	C654.430298,662.037170 649.119263,655.897156 647.416382,647.109680 
	C646.062866,640.124939 645.282471,632.922424 645.241577,625.810974 
	C645.138184,607.829407 645.419800,589.839355 645.956726,571.864990 
	C646.082520,567.656250 647.832397,563.496094 648.967712,558.961914 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M463.800995,581.097656 
	C463.200256,591.182922 463.357819,600.908936 461.789307,610.348083 
	C459.810577,622.256104 453.697815,626.959351 441.568665,627.801575 
	C437.307709,628.097412 433.008911,627.848877 428.199585,627.848877 
	C428.199585,639.475769 428.199585,650.390625 428.199585,661.570190 
	C420.258606,661.570190 412.853058,661.570190 405.153107,661.570190 
	C405.153107,624.206360 405.153107,586.985107 405.153107,548.717102 
	C419.477081,549.267273 433.591064,549.295593 447.597504,550.537170 
	C456.209229,551.300415 461.287140,558.720825 462.907257,570.170044 
	C463.395844,573.622986 463.508179,577.129272 463.800995,581.097656 
M428.149048,581.567993 
	C428.149048,589.163452 428.149048,596.758911 428.149048,604.243958 
	C436.902405,605.743591 439.478760,603.892822 439.963684,595.798401 
	C440.241180,591.166138 440.192352,586.500671 440.034363,581.858948 
	C439.759155,573.772400 436.463043,571.514832 428.149048,573.779053 
	C428.149048,575.991516 428.149048,578.292664 428.149048,581.567993 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M833.975952,590.217102 
	C830.024841,576.483643 826.165100,563.146545 822.140686,549.240295 
	C830.037903,549.240295 837.159058,549.092285 844.260193,549.402771 
	C845.303467,549.448425 846.820801,551.533936 847.152405,552.908752 
	C849.530212,562.766174 851.654480,572.684814 853.871033,582.581299 
	C854.074463,583.489685 854.361328,584.379395 855.023804,586.777649 
	C855.929077,584.150940 856.482605,582.919006 856.777954,581.627930 
	C858.925659,572.239380 861.060547,562.847473 863.110413,553.437195 
	C863.755554,550.475464 864.953918,548.938477 868.429199,549.127197 
	C874.542908,549.459351 880.687683,549.220154 887.742493,549.220154 
	C885.692932,556.867126 883.850830,563.996216 881.867859,571.085876 
	C877.570435,586.450317 873.100098,601.767395 868.944092,617.169556 
	C868.094116,620.319580 868.104675,623.756042 868.076416,627.063538 
	C867.978638,638.503845 868.038757,649.945435 868.038757,661.611633 
	C859.587036,661.611633 851.658264,661.611633 842.591553,661.611633 
	C844.485168,637.324707 843.207092,613.433289 833.975952,590.217102 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M799.302185,573.090637 
	C795.074402,572.852356 796.114868,575.667480 796.111389,577.585938 
	C796.066406,602.564575 796.081604,627.543213 796.081726,652.521912 
	C796.081726,655.475342 796.081665,658.428772 796.081665,661.599365 
	C788.123535,661.599365 780.708679,661.599365 772.847229,661.599365 
	C772.847229,632.189941 772.847229,602.948303 772.847229,573.210632 
	C766.841125,573.210632 761.264099,573.210632 755.341431,573.210632 
	C755.341431,565.145325 755.341431,557.567932 755.341431,549.598877 
	C774.508362,549.598877 793.726929,549.598877 813.366455,549.598877 
	C813.366455,557.092896 813.366455,564.646301 813.366455,573.093323 
	C808.914124,573.093323 804.344360,573.093323 799.302185,573.090637 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M718.744934,606.999268 
	C718.778809,587.846252 718.810791,569.193298 718.863708,550.540405 
	C718.864563,550.241577 719.122131,549.943542 719.357727,549.433411 
	C726.864075,549.433411 734.464966,549.433411 742.442566,549.433411 
	C742.442566,586.749023 742.442566,623.944214 742.442566,661.490112 
	C734.653381,661.490112 726.939270,661.490112 718.744934,661.490112 
	C718.744934,643.407715 718.744934,625.453491 718.744934,606.999268 
z"/>
<path fill="#1D62AA" opacity="1.000000" stroke="none" 
	d="
M670.900757,722.014526 
	C666.293579,722.014771 662.168213,722.014771 657.769165,722.014771 
	C657.769165,709.689575 657.769165,697.794312 657.769165,684.917419 
	C666.406067,685.715576 675.167969,683.334595 683.558533,687.108948 
	C690.634521,690.292114 693.841797,696.758362 693.077942,705.613647 
	C692.423157,713.204590 687.940735,718.875427 680.712524,720.721741 
	C677.689697,721.493835 674.497620,721.603394 670.900757,722.014526 
M668.665894,704.482666 
	C668.665894,707.233704 668.665894,709.984741 668.665894,712.614502 
	C679.608887,713.964539 684.755615,708.060974 681.327332,699.072205 
	C679.838440,695.168457 675.726196,692.890747 671.247375,693.691345 
	C666.741455,694.496887 669.308350,698.157471 668.699097,700.518188 
	C668.458008,701.452148 668.662842,702.501221 668.665894,704.482666 
z"/>
<path fill="#1D62AA" opacity="1.000000" stroke="none" 
	d="
M753.223022,721.995789 
	C751.453796,721.569519 749.090393,721.379761 748.943420,720.706238 
	C747.630005,714.689026 743.037781,716.045410 739.081238,716.023254 
	C735.562622,716.003601 731.558044,714.916260 730.583008,720.399963 
	C730.442810,721.188171 727.581238,721.723938 725.921021,721.949890 
	C723.976929,722.214417 721.969238,722.011780 719.587646,722.011780 
	C719.724548,720.936523 719.657959,720.126953 719.937439,719.463135 
	C724.386292,708.899475 729.037109,698.417175 733.270569,687.768677 
	C734.613953,684.389587 737.013428,684.957153 739.354126,685.091370 
	C742.092896,685.248474 745.425171,683.691833 747.032715,687.735474 
	C751.508057,698.992920 756.139221,710.188477 760.952942,722.007935 
	C758.183777,722.007935 755.920227,722.007935 753.223022,721.995789 
M741.626892,700.923462 
	C741.192749,699.770325 740.758606,698.617249 739.887390,696.303162 
	C738.208435,700.730042 736.969543,703.996460 735.592773,707.626587 
	C738.707703,707.626587 741.091736,707.626587 744.089966,707.626587 
	C743.223389,705.296631 742.542786,703.466797 741.626892,700.923462 
z"/>
<path fill="#1D62AA" opacity="1.000000" stroke="none" 
	d="
M842.102417,722.002930 
	C840.351257,722.010010 839.038269,722.010010 836.812317,722.010010 
	C838.404602,718.138428 839.729370,714.854492 841.102600,711.590942 
	C844.389954,703.778870 847.927673,696.062256 850.918274,688.138733 
	C852.810059,683.126526 856.704956,685.629456 859.776550,685.084106 
	C862.324829,684.631653 863.921875,685.467834 865.010864,688.279785 
	C869.313293,699.388550 873.974609,710.358337 878.766602,722.021729 
	C875.048279,722.021729 872.069702,722.156128 869.116211,721.943298 
	C868.175537,721.875549 867.106201,721.052490 866.440918,720.278320 
	C865.341309,718.998779 864.621887,716.350464 863.567078,716.256958 
	C859.299500,715.878845 854.963074,716.214966 850.658203,716.418152 
	C850.227112,716.438599 849.530518,717.314087 849.477417,717.844788 
	C849.020691,722.409912 845.833679,722.262939 842.102417,722.002930 
M856.801758,707.900208 
	C858.329346,707.900208 859.856995,707.900208 862.087891,707.900208 
	C860.444153,703.676392 859.198303,700.475037 857.593994,696.352478 
	C856.162170,700.079346 854.989319,702.655762 854.267944,705.353027 
	C854.092834,706.007935 855.404907,707.060547 856.801758,707.900208 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M325.325317,550.345215 
	C324.354462,551.026062 323.690674,551.524475 323.010468,551.999451 
	C313.525543,558.622620 307.479095,556.730408 303.105621,545.776855 
	C302.304810,543.771179 301.529297,541.754333 300.676514,539.770813 
	C296.430695,529.895630 296.289673,521.900513 306.386536,513.752441 
	C310.212280,510.665161 313.548157,506.930298 316.883698,503.285919 
	C321.413940,498.336151 328.229431,496.813171 334.109375,500.261505 
	C338.672058,502.937347 343.067963,505.985535 347.192444,509.298401 
	C352.446655,513.518677 353.666595,519.715088 349.816833,525.286743 
	C346.565125,529.992859 342.348755,534.071655 338.311188,538.184509 
	C334.241608,542.329895 329.866211,546.175171 325.325317,550.345215 
M346.829987,514.250977 
	C342.044067,511.075806 337.440796,507.566864 332.420563,504.819916 
	C327.453094,502.101807 323.337128,503.108643 319.396576,507.073883 
	C315.769867,510.723267 312.596558,514.953979 308.528381,518.000427 
	C303.576904,521.708374 302.205353,526.270813 303.015564,531.884399 
	C303.554901,535.621155 304.059998,539.435425 305.247467,542.992310 
	C308.477417,552.667114 315.275177,554.093323 322.590332,546.990845 
	C327.715729,542.014404 333.042023,537.245483 338.206238,532.308289 
	C343.129150,527.601746 348.679382,523.163086 346.829987,514.250977 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M425.383881,463.583008 
	C422.979919,462.360840 420.778320,461.467529 418.936646,460.092224 
	C415.226959,457.321869 413.341858,453.908630 414.724640,448.898438 
	C415.995209,444.294708 416.629395,439.486816 417.199585,434.727600 
	C417.629150,431.142395 419.904022,429.675079 422.884308,429.581116 
	C435.692200,429.177368 448.004486,431.299316 459.493683,437.341675 
	C464.509247,439.979492 465.998627,444.260345 465.415375,449.436035 
	C465.006989,453.059906 464.291992,456.678711 463.367981,460.209259 
	C461.092743,468.902893 455.502716,472.457458 446.623474,470.258087 
	C439.556915,468.507660 432.699402,465.913391 425.383881,463.583008 
M435.537964,435.983948 
	C433.511902,435.427399 431.508301,434.758392 429.453125,434.347504 
	C427.581573,433.973389 425.509247,433.334106 423.785614,433.791748 
	C421.289581,434.454498 416.746063,452.115387 418.719849,453.769409 
	C421.972504,456.495117 425.535980,459.127625 429.405060,460.772339 
	C434.681030,463.015137 440.415283,464.152710 445.863342,466.025177 
	C451.416168,467.933624 457.957153,464.994568 459.744843,459.368439 
	C460.595734,456.690491 461.544250,453.934113 461.700806,451.170898 
	C461.929443,447.135254 461.449524,442.695496 456.892242,441.248413 
	C450.184082,439.118347 443.246490,437.710785 435.537964,435.983948 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M300.700867,564.426025 
	C298.591522,568.143555 297.228027,572.384033 294.319458,574.537476 
	C287.414215,579.650085 279.941925,584.077515 272.376312,588.188354 
	C267.507324,590.833862 262.906342,588.528259 261.700134,583.076172 
	C258.859344,570.235901 260.131226,557.596497 264.916199,545.389954 
	C265.565155,543.734497 267.501892,542.399292 269.121490,541.344360 
	C273.553711,538.457275 278.155731,535.832397 282.651062,533.040161 
	C286.790253,530.469177 291.343018,531.097961 293.048645,535.556030 
	C296.561707,544.738220 302.020752,553.494385 300.700867,564.426025 
M267.843018,551.287781 
	C262.685150,559.797852 263.782898,569.232483 264.196411,578.437195 
	C264.417847,583.366455 268.453522,585.477722 272.752838,583.008484 
	C278.806335,579.531921 284.446075,575.339050 290.298645,571.507385 
	C297.036316,567.096375 299.360046,561.390381 296.876343,554.398804 
	C295.066498,549.303955 292.991119,544.300171 290.928070,539.299622 
	C289.429871,535.668213 287.076172,535.200989 283.933685,537.495361 
	C281.557739,539.230103 279.160156,541.480225 276.452515,542.096558 
	C271.552490,543.212036 268.889038,545.758484 267.843018,551.287781 
z"/>
<path fill="#2263AA" opacity="1.000000" stroke="none" 
	d="
M815.554810,687.311279 
	C815.562805,689.529053 815.562805,691.316467 815.562805,692.792358 
	C814.748657,693.273193 814.479858,693.562622 814.191772,693.583191 
	C804.836243,694.251038 804.833618,694.247192 804.825378,703.882507 
	C804.820496,709.675476 804.824463,715.468445 804.824463,721.637573 
	C801.183228,721.637573 797.956726,721.637573 794.097656,721.637573 
	C794.097656,712.661743 794.097656,703.615234 794.097656,694.098389 
	C790.289307,693.891357 787.059509,693.715759 783.444336,693.519165 
	C783.444336,690.757507 783.444336,688.186707 783.444336,685.054749 
	C793.423523,685.054749 803.507751,685.020630 813.590454,685.133118 
	C814.249023,685.140564 814.895020,686.272278 815.554810,687.311279 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M295.758362,707.630005 
	C289.115631,711.780029 280.308533,710.891479 274.763184,704.514282 
	C268.806488,697.664062 263.773712,689.985779 258.609711,682.481689 
	C256.175568,678.944458 254.270035,675.023499 252.337433,671.169800 
	C251.421265,669.342896 251.003723,667.265869 250.391144,665.396362 
	C256.825104,665.878418 262.936554,665.441895 268.461334,667.037170 
	C272.772461,668.282043 277.262482,671.329102 280.067108,674.853333 
	C285.511200,681.694397 290.183868,689.236084 294.424744,696.903198 
	C296.011993,699.772888 295.504242,703.801331 295.758362,707.630005 
M275.907928,698.596680 
	C282.753052,706.620544 284.286652,707.145569 291.992828,703.824341 
	C291.140930,701.403076 290.641602,698.739929 289.349854,696.542603 
	C285.257355,689.581238 281.078461,682.650940 276.528656,675.984497 
	C272.868530,670.621643 264.018097,668.914917 258.600098,671.892639 
	C261.996124,682.046875 267.868958,690.624512 275.907928,698.596680 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M328.928955,478.945496 
	C327.164093,487.189026 320.049469,485.265320 314.099121,486.366577 
	C317.849091,493.013306 315.252289,497.881348 310.252106,502.145386 
	C307.480072,504.509277 304.954865,507.175110 302.096710,509.422943 
	C295.999237,514.218384 295.562653,514.151367 289.351776,509.392487 
	C289.161041,510.658386 288.983551,511.674225 288.857788,512.696472 
	C287.725769,521.900024 279.139221,530.729614 270.119568,531.822021 
	C269.507538,531.896179 268.792847,531.122437 268.452209,530.366577 
	C275.577209,528.270813 282.442108,525.225342 285.162811,518.999817 
	C289.283569,509.570770 287.104065,499.331299 285.687561,489.492065 
	C280.770416,492.206085 276.404907,494.615601 272.039368,497.025146 
	C273.995422,490.677216 279.544739,489.023407 284.921997,487.098755 
	C286.393921,486.571930 287.771606,485.781738 289.566345,485.448303 
	C287.887360,492.970093 289.210754,499.975250 291.478973,506.785980 
	C293.017761,511.406403 295.725433,512.147156 299.397461,509.081512 
	C303.721527,505.471466 307.898499,501.645447 311.815308,497.599457 
	C315.226654,494.075562 314.752380,490.010315 310.654114,487.470703 
	C305.270569,484.134674 299.646362,481.165009 292.927216,481.895996 
	C296.441315,476.332367 299.955414,470.768738 303.751404,465.550110 
	C303.807922,466.755310 303.582642,467.615540 303.092316,469.487885 
	C306.614105,467.739655 309.247284,466.432556 312.270508,465.092804 
	C318.413696,463.255951 324.166840,461.451782 330.268799,459.686707 
	C332.129303,459.350555 333.640930,458.975250 335.152557,458.599976 
	C340.321167,465.918610 340.176514,466.880310 332.378357,474.609375 
	C333.174225,472.510315 334.452087,470.797089 335.351929,468.904144 
	C336.008240,467.523590 336.891266,465.508118 336.341553,464.506927 
	C335.797882,463.516785 333.574432,463.047699 332.103912,463.080170 
	C330.623108,463.112854 329.162109,464.044037 327.285522,464.613342 
	C319.536011,467.022827 312.193420,469.400452 304.480652,471.806519 
	C302.449707,473.222534 300.682373,474.512390 299.233093,476.093689 
	C298.991028,476.357819 299.891998,478.212952 300.668518,478.783478 
	C308.657471,484.653259 321.024933,484.670502 328.928955,478.945496 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M271.789856,497.202576 
	C276.404907,494.615601 280.770416,492.206085 285.687561,489.492065 
	C287.104065,499.331299 289.283569,509.570770 285.162811,518.999817 
	C282.442108,525.225342 275.577209,528.270813 268.398621,530.017761 
	C268.018707,530.045349 267.991852,530.006531 267.999695,529.557922 
	C268.007507,526.611328 268.007507,524.113403 268.007507,521.615417 
	C267.551361,521.535339 267.095215,521.455261 266.639069,521.375183 
	C264.685699,524.759705 263.063995,528.412903 260.634827,531.411926 
	C259.473145,532.846069 256.780182,533.039856 254.780777,533.795410 
	C254.439545,531.916687 253.442810,529.862305 253.889603,528.194702 
	C254.909470,524.387939 256.636200,520.770508 258.075806,517.076172 
	C259.092682,515.345093 260.109558,513.614014 261.525024,511.456848 
	C262.301819,510.373016 262.680054,509.715271 263.058289,509.057495 
	C263.058258,509.057495 263.210114,508.657135 263.561462,508.346375 
	C264.272705,507.354034 264.632599,506.672485 264.992462,505.990906 
	C264.992462,505.990906 265.130035,505.603790 265.496277,505.318604 
	C266.259247,504.371704 266.655945,503.709961 267.052673,503.048248 
	C267.052673,503.048248 267.233063,502.667297 267.581360,502.362457 
	C268.338287,501.430573 268.746918,500.803528 269.155579,500.176483 
	C269.950500,499.244324 270.745392,498.312134 271.789856,497.202576 
M275.843781,504.394318 
	C274.229980,509.904755 272.154663,515.333557 271.160004,520.953552 
	C270.463684,524.887939 272.784119,526.279053 276.240723,524.230225 
	C280.039886,521.978333 283.928558,519.459106 284.174133,514.180847 
	C284.366394,510.048859 284.641998,505.916382 285.041809,501.799774 
	C285.440613,497.693542 283.666626,496.033447 279.734833,496.323669 
	C278.473755,498.928467 277.318451,501.314850 275.843781,504.394318 
z"/>
<path fill="#446993" opacity="1.000000" stroke="none" 
	d="
M296.051147,717.905396 
	C305.046417,717.027100 312.665985,719.625488 319.559784,724.361084 
	C326.128357,728.873230 332.633148,733.516052 338.807739,738.541565 
	C340.725281,740.102356 341.472900,743.100647 342.756134,745.440796 
	C340.456909,746.038513 338.175293,747.059937 335.855194,747.156372 
	C328.121521,747.477905 321.706573,743.675964 315.680267,739.546875 
	C306.163483,733.026245 296.962891,726.044189 287.628479,719.257385 
	C287.888519,718.699280 288.148560,718.141174 288.408569,717.583130 
	C290.800568,717.691467 293.192566,717.799805 296.051147,717.905396 
M306.157837,729.480408 
	C313.353394,733.728638 320.573151,737.936646 327.732117,742.245789 
	C330.430481,743.869934 332.965820,745.042603 336.038666,741.643860 
	C333.042694,739.303894 330.335175,737.046692 327.482361,734.991028 
	C321.728851,730.845520 316.000580,726.646973 310.034271,722.825684 
	C308.203003,721.652771 305.652863,721.444824 303.387146,721.183716 
	C302.459930,721.076904 301.029724,721.764587 300.556030,722.537109 
	C300.235535,723.059692 300.977722,724.539490 301.611237,725.275635 
	C302.793701,726.649658 304.243011,727.793945 306.157837,729.480408 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M328.901001,592.116455 
	C318.690918,583.364929 319.084595,568.014893 329.253845,558.922180 
	C337.895416,551.195435 346.141937,543.009705 354.307617,534.773621 
	C360.021545,529.010437 371.105835,527.059509 378.294220,531.960693 
	C383.320435,535.387756 388.000183,539.635071 391.933990,544.278992 
	C397.802643,551.207092 397.079834,559.079956 390.687286,566.991638 
	C389.254456,568.764954 387.314301,570.128418 385.331055,571.396851 
	C386.199097,569.729370 387.360291,568.358826 388.485474,566.959351 
	C391.836853,562.790894 394.684753,558.477356 394.034882,552.687561 
	C393.474457,547.694702 388.846283,542.026001 384.584778,541.941162 
	C383.488098,541.941650 382.756195,541.932983 381.871521,541.625610 
	C370.891907,529.596130 361.725220,532.145264 354.484222,541.091553 
	C346.611725,550.818054 335.914520,557.107544 327.878296,566.371826 
	C327.512390,566.793640 326.662476,566.795593 325.711426,567.169434 
	C322.205505,572.151611 322.109009,577.124207 324.501862,582.274719 
	C326.015717,585.533264 327.437775,588.834412 328.901001,592.116455 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M392.278870,466.351013 
	C392.150146,468.255615 392.021423,470.160248 391.479309,472.540161 
	C389.710999,475.341187 388.356171,477.666870 387.001343,479.992554 
	C387.001312,479.992554 386.857330,480.393158 386.489136,480.681488 
	C385.748505,481.640167 385.376007,482.310516 385.003540,482.980896 
	C385.003571,482.980896 384.850983,483.377869 384.468445,483.684937 
	C381.020996,488.283905 377.956085,492.575836 374.891174,496.867737 
	C374.891174,496.867737 374.925659,496.923553 374.595398,497.064697 
	C365.406982,505.771118 361.699890,506.573822 350.768951,501.665680 
	C346.407471,499.707336 342.294647,497.195190 338.068634,494.935120 
	C337.770020,494.800812 337.513794,494.609680 337.125793,493.790039 
	C333.356262,489.150391 335.766144,485.186005 337.158905,481.204102 
	C337.158905,481.204102 337.152893,481.145569 337.503967,481.061798 
	C340.255890,477.339783 342.656738,473.701538 345.057587,470.063293 
	C345.763489,469.001984 346.469421,467.940674 347.610046,466.471161 
	C358.712585,457.668549 381.663208,457.818024 392.278870,466.351013 
M343.404938,478.943817 
	C341.989594,481.381927 340.164429,483.687103 339.314362,486.308533 
	C338.813354,487.853485 339.222748,490.745758 340.320831,491.571136 
	C344.595459,494.784332 349.170898,497.673096 353.897125,500.189087 
	C358.935089,502.871033 368.615936,500.133728 371.565216,495.755402 
	C376.369965,488.622406 381.260284,481.547028 386.126923,474.455902 
	C386.501099,473.910645 387.073761,473.486938 387.383209,472.914307 
	C389.316376,469.336884 387.171906,464.500336 383.142700,464.110931 
	C376.721069,463.490356 370.217926,462.776154 363.819824,463.190826 
	C359.800995,463.451294 355.231140,464.843445 352.143372,467.287781 
	C348.641968,470.059631 346.519867,474.573822 343.404938,478.943817 
z"/>
<path fill="#446993" opacity="1.000000" stroke="none" 
	d="
M350.965240,682.090088 
	C350.780212,682.274414 350.595154,682.458740 349.829041,682.813232 
	C348.824280,683.335388 348.400543,683.687439 347.976776,684.039429 
	C347.195435,684.629211 346.414093,685.219055 344.935883,685.931763 
	C342.526215,687.291077 340.813385,688.527588 339.100586,689.764038 
	C339.100586,689.764038 339.002075,690.014343 338.754089,690.064270 
	C338.506104,690.114197 338.184753,690.453308 338.184753,690.453308 
	C337.972046,690.580261 337.759338,690.707275 336.954041,690.924438 
	C335.900391,691.367859 335.439331,691.721252 334.978241,692.074585 
	C334.097717,692.617859 333.217194,693.161133 331.698975,693.852051 
	C330.366394,694.516602 329.671478,695.033508 328.976562,695.550415 
	C328.976562,695.550476 328.879059,695.806030 328.389618,695.867798 
	C322.249329,696.981262 316.598511,698.033020 310.947662,699.084839 
	C310.162567,698.860840 309.377472,698.636841 308.183319,697.963013 
	C307.271301,697.320496 306.768372,697.127747 306.265411,696.934998 
	C306.036072,696.882324 305.806763,696.829712 305.244049,696.264099 
	C303.161041,694.155640 301.411438,692.560120 299.661804,690.964661 
	C299.506744,690.808411 299.351715,690.652222 299.106201,689.840820 
	C297.814972,687.777100 296.614258,686.368652 295.413574,684.960205 
	C295.035645,684.462036 294.657715,683.963928 294.112122,682.840759 
	C293.673431,681.093994 293.402405,679.972229 293.475891,678.903259 
	C295.877838,680.969482 297.935333,682.982910 300.246887,685.225952 
	C301.771851,686.879272 303.148224,688.223328 304.295685,689.740479 
	C309.492615,696.612061 317.501129,698.222656 324.786377,693.806885 
	C332.161255,689.336670 339.865448,685.323120 346.820435,680.275879 
	C351.760010,676.691223 355.813904,671.831116 359.973236,667.275635 
	C360.751434,666.423279 360.753845,663.961853 360.092346,662.852051 
	C357.265015,658.108704 354.054749,653.593628 350.839783,648.696655 
	C347.774170,645.903870 344.852905,643.409607 341.947052,640.557617 
	C342.889221,636.354492 344.707611,638.389099 346.114838,639.696838 
	C349.389130,642.739563 352.528259,645.927612 355.722168,649.056824 
	C355.722168,649.056824 355.891571,649.042419 355.947937,649.392456 
	C356.496307,650.203186 356.988281,650.663879 357.480225,651.124573 
	C357.940460,651.575989 358.400726,652.027405 358.960083,653.149658 
	C360.677490,656.860474 362.295776,659.900452 363.914062,662.940430 
	C364.002869,664.043518 364.091705,665.146667 363.808105,666.862915 
	C363.319275,668.335144 363.202881,669.194214 363.086456,670.053223 
	C360.832642,672.941895 358.578827,675.830566 355.772461,678.845032 
	C354.805878,679.325745 354.391815,679.680664 353.977753,680.035583 
	C353.801361,680.221802 353.624969,680.408081 352.875916,680.793335 
	C351.857239,681.358154 351.411224,681.724121 350.965240,682.090088 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M293.131409,678.850464 
	C293.402405,679.972229 293.673431,681.093994 293.967773,682.562378 
	C289.701965,678.803711 287.899536,673.602905 287.631348,667.843872 
	C287.385620,662.566589 290.512146,659.280640 294.719086,656.748047 
	C305.342621,650.352783 315.990509,643.997070 326.563232,637.518799 
	C333.538361,633.244812 341.791962,633.531738 347.761475,639.007141 
	C350.750549,641.748779 353.064026,645.226990 355.702576,648.714478 
	C352.528259,645.927612 349.389130,642.739563 346.114838,639.696838 
	C344.707611,638.389099 342.889221,636.354492 341.598145,640.411865 
	C336.712799,639.486145 332.304626,638.702148 327.976868,641.576660 
	C320.191986,646.747620 312.204590,651.614746 304.440887,656.816406 
	C298.534729,660.773560 290.256561,663.060547 291.652466,673.017700 
	C291.906281,674.738708 292.468475,676.404663 293.050903,678.266174 
	C293.071136,678.461792 293.131409,678.850464 293.131409,678.850464 
M337.905090,637.730713 
	C338.426819,637.548767 338.948517,637.366882 339.470215,637.184937 
	C339.346863,636.918396 339.223511,636.651917 339.100220,636.385437 
	C338.563995,636.694702 338.027802,637.004028 337.905090,637.730713 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M384.949554,541.932068 
	C388.846283,542.026001 393.474457,547.694702 394.034882,552.687561 
	C394.684753,558.477356 391.836853,562.790894 388.485474,566.959351 
	C387.360291,568.358826 386.199097,569.729370 385.003052,571.438110 
	C384.951782,571.763062 384.954956,571.903015 384.572235,571.965271 
	C383.105286,573.184998 382.021057,574.342468 380.936829,575.500000 
	C380.656311,575.846741 380.375763,576.193542 379.529022,576.807129 
	C372.616394,583.171204 366.269958,589.268555 359.923523,595.365906 
	C359.589508,595.725281 359.255463,596.084656 358.332153,596.692688 
	C356.481476,598.089905 355.220062,599.238525 353.958618,600.387146 
	C353.787323,600.509583 353.615997,600.632019 352.786621,600.868164 
	C344.548798,604.949097 338.307495,603.180847 333.082703,596.650452 
	C331.993561,595.289185 330.573090,594.193054 329.305328,592.974670 
	C329.305328,592.974670 329.007599,592.590576 328.954285,592.353516 
	C327.437775,588.834412 326.015717,585.533264 324.501862,582.274719 
	C322.109009,577.124207 322.205505,572.151611 325.699310,567.582886 
	C325.224945,574.178711 324.280731,580.562866 329.148193,586.322144 
	C332.756287,590.551392 335.990814,594.707520 339.740601,598.330200 
	C342.566101,601.059937 346.194763,600.929932 349.238190,598.403625 
	C352.883667,595.377625 356.235504,592.000671 359.779236,588.848572 
	C368.107483,581.440491 376.675964,574.286255 384.717194,566.580566 
	C387.568787,563.847900 389.629425,560.012878 391.232025,556.325195 
	C393.151215,551.908813 390.602997,547.485718 386.031586,545.706299 
	C386.027222,545.469604 385.994507,544.997375 385.866272,544.698120 
	C385.475220,543.576660 385.212372,542.754333 384.949554,541.932068 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M272.815247,638.231628 
	C267.110565,628.326416 264.200562,617.753784 267.359375,606.535889 
	C268.217102,603.489868 271.605560,600.717407 274.475830,598.765442 
	C281.168518,594.213989 288.398102,590.456970 295.142426,585.975220 
	C304.597229,579.692261 312.952881,581.869751 317.499542,592.474487 
	C316.067078,592.009094 314.750916,589.596741 314.000732,592.999390 
	C313.998291,593.001465 313.991241,593.007690 313.650024,592.921997 
	C308.225616,585.337708 303.301819,584.772705 296.339752,589.994324 
	C291.295868,593.777344 285.814178,597.096069 280.187653,599.951904 
	C273.372711,603.411011 268.949280,607.915283 269.942444,616.116882 
	C270.297699,619.050354 270.577850,621.992920 270.646057,625.200317 
	C271.204987,629.723450 272.010101,633.977539 272.815247,638.231628 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M500.927155,457.188293 
	C503.478088,458.936890 506.029022,460.685486 508.258606,462.697754 
	C505.872620,462.977234 504.769226,457.531891 501.589661,461.877625 
	C497.894989,459.781311 494.394135,457.922577 491.233490,455.600769 
	C484.533356,450.678894 479.018707,452.830566 478.042023,461.224854 
	C477.281250,467.763336 474.695892,474.247833 477.025208,481.297302 
	C477.726807,483.015442 478.395569,484.396210 479.064331,485.776947 
	C474.324310,483.213287 470.953888,477.283264 471.787598,472.319946 
	C473.129364,464.331879 474.696014,456.381226 476.199158,448.420746 
	C477.027771,444.032440 480.452179,444.794373 482.778412,445.996857 
	C488.901642,449.162018 494.715179,452.926361 500.400024,456.734009 
	C499.291138,456.807373 498.434296,456.598755 497.577423,456.390137 
	C497.610291,456.966614 497.643188,457.543121 497.676056,458.119629 
	C498.759735,457.809174 499.843445,457.498718 500.927155,457.188293 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M479.177307,486.046814 
	C478.395569,484.396210 477.726807,483.015442 477.371460,481.351685 
	C480.786621,483.177795 483.901306,485.268219 486.986603,487.401093 
	C490.452393,489.796967 494.106750,491.979919 497.292999,494.706573 
	C502.284302,498.977875 507.283295,497.827545 509.323975,491.815704 
	C510.106964,489.508972 511.584442,487.413086 512.897522,485.316315 
	C515.706909,480.830200 515.288574,476.181152 512.357544,472.325134 
	C509.412354,468.450470 505.452698,465.346893 501.937622,461.905396 
	C504.769226,457.531891 505.872620,462.977234 508.246033,463.023499 
	C509.226776,463.648651 509.898743,464.211731 510.796326,465.312439 
	C511.543762,466.256348 512.065613,466.662659 512.587402,467.068970 
	C514.151428,469.682617 515.715393,472.296234 517.179077,475.670380 
	C517.236450,477.890442 517.394165,479.350006 517.551819,480.809570 
	C517.487671,481.940033 517.423523,483.070526 516.960571,484.740784 
	C516.453186,485.951874 516.344666,486.623108 516.236084,487.294373 
	C515.752625,489.543854 515.441956,491.847656 514.755127,494.033234 
	C512.118286,502.423584 509.201019,503.783173 500.301147,500.273499 
	C499.020355,499.493225 498.191589,499.193787 497.362854,498.894348 
	C497.093262,498.773926 496.823639,498.653503 496.166290,498.333923 
	C495.778595,498.134796 495.787445,498.112976 495.787445,498.112976 
	C495.394012,497.794983 495.000580,497.476959 494.253967,496.602295 
	C492.288086,495.031097 490.675354,494.016510 489.062622,493.001923 
	C487.907288,492.288330 486.751953,491.574738 485.250977,490.390930 
	C484.308502,489.601593 483.711639,489.282440 483.114807,488.963318 
	C483.114807,488.963318 483.037659,488.984528 482.969666,488.621368 
	C482.191772,487.889313 481.481873,487.520416 480.771973,487.151520 
	C480.278076,486.873230 479.784180,486.594971 479.177307,486.046814 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M272.926788,638.520996 
	C272.010101,633.977539 271.204987,629.723450 270.916962,625.425049 
	C271.953583,626.918823 272.473083,628.456970 273.033997,630.363770 
	C273.490509,631.994202 273.798615,633.303894 274.339203,634.509399 
	C276.653076,639.669861 281.569183,643.755615 285.438721,642.874573 
	C289.381012,641.976929 293.389984,640.559937 296.840668,638.488831 
	C303.468994,634.510376 309.608704,629.725464 316.168488,625.622681 
	C321.644745,622.197571 324.514923,614.393921 322.139374,608.579407 
	C321.419098,606.816528 321.381042,604.774902 321.362885,602.943420 
	C323.186249,605.032471 324.677246,607.039062 326.168213,609.045593 
	C326.319031,609.518005 326.469849,609.990417 326.415649,611.033813 
	C326.403229,612.363037 326.595825,613.121338 326.788452,613.879639 
	C327.413300,623.780396 320.319183,628.202820 312.709534,632.855103 
	C308.313843,635.378479 304.606018,637.794312 300.898193,640.210083 
	C300.487762,640.439392 300.077362,640.668640 298.949585,640.973145 
	C295.522919,642.228394 292.749939,643.282776 290.118286,644.616150 
	C285.483582,646.964600 281.312469,646.723511 277.440430,643.035461 
	C276.530182,642.168457 275.281036,641.657288 274.187805,640.982361 
	C273.804657,640.258362 273.421509,639.534363 272.926788,638.520996 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M256.786377,577.993042 
	C256.590668,580.070557 256.394958,582.148010 255.893967,584.838501 
	C252.822296,594.945923 250.518341,596.205872 240.921890,593.479492 
	C240.291458,593.300415 239.650940,593.156677 239.015121,592.996521 
	C239.015121,592.996521 239.002441,592.997559 238.989349,592.570862 
	C235.911407,577.064636 239.839691,563.010315 245.440109,549.253235 
	C245.866699,548.205383 247.615875,547.091431 248.786621,547.050598 
	C255.639435,546.811707 257.230835,548.413757 257.106232,555.361267 
	C257.035248,559.319641 256.402863,563.267883 256.330963,567.226318 
	C256.265808,570.811279 256.618134,574.403809 256.786377,577.993042 
M241.979675,567.652832 
	C241.803024,568.795837 241.505219,569.935059 241.470108,571.082397 
	C241.290085,576.963257 240.754242,582.879272 241.210648,588.715088 
	C241.459061,591.891296 246.245453,593.543396 248.566559,591.412048 
	C250.516144,589.621826 252.623657,586.929504 252.817780,584.489929 
	C253.606400,574.579407 253.767242,564.613586 253.917496,554.664490 
	C253.935699,553.458984 252.532745,551.265320 251.702942,551.223511 
	C250.177261,551.146423 247.709747,551.921387 247.180389,553.059875 
	C245.121567,557.487671 243.669601,562.197632 241.979675,567.652832 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M292.928162,481.908356 
	C299.646362,481.165009 305.270569,484.134674 310.654114,487.470703 
	C314.752380,490.010315 315.226654,494.075562 311.815308,497.599457 
	C307.898499,501.645447 303.721527,505.471466 299.397461,509.081512 
	C295.725433,512.147156 293.017761,511.406403 291.478973,506.785980 
	C289.210754,499.975250 287.887360,492.970093 289.917175,485.361206 
	C289.894287,484.938171 290.081177,484.567047 290.462891,484.309082 
	C291.533417,483.340454 292.222198,482.629852 292.911011,481.919250 
	C292.911011,481.919250 292.929077,481.920715 292.928162,481.908356 
M294.469299,505.697479 
	C295.480408,506.010590 296.958588,506.915619 297.429474,506.544067 
	C301.566803,503.279449 305.727386,499.984161 309.385071,496.216400 
	C310.238495,495.337341 309.878296,492.036621 308.880798,490.893555 
	C307.358459,489.149139 304.919983,487.955414 302.648895,487.162933 
	C297.243896,485.276794 290.418976,489.903748 291.451569,494.580353 
	C292.223785,498.077606 293.236938,501.521667 294.469299,505.697479 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M240.788452,637.292847 
	C239.318726,631.095032 237.771286,624.914062 236.428909,618.688782 
	C236.049713,616.930298 236.230240,615.042664 236.223114,613.213989 
	C236.191696,605.143311 236.200668,605.143982 244.209229,606.867126 
	C244.696136,606.971802 245.185287,607.078125 245.678970,607.137451 
	C256.411224,608.427856 258.609894,610.286316 261.375885,620.701416 
	C262.139893,623.578125 262.651245,626.521973 262.808960,629.456543 
	C261.845459,627.800171 261.499084,626.060181 260.825195,624.457947 
	C259.627594,621.610596 258.231537,618.846741 256.592529,615.965210 
	C252.198349,608.945068 244.977020,612.692200 239.164658,609.848267 
	C240.165131,618.262756 241.038956,625.611938 241.658417,633.240234 
	C241.198853,634.777161 240.993637,636.035034 240.788452,637.292847 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M392.441406,466.013489 
	C381.663208,457.818024 358.712585,457.668549 347.741150,466.178284 
	C348.605316,464.428040 349.393707,462.047607 351.041504,460.833527 
	C352.895966,459.467163 355.525269,459.152344 358.342285,458.203278 
	C357.484436,456.549622 356.776215,455.184418 356.070953,453.375214 
	C356.073914,452.931213 356.273041,452.741272 356.618958,452.879333 
	C359.330872,453.657715 361.677887,454.752869 364.066345,454.852386 
	C371.027222,455.142456 378.008362,454.912140 384.974487,455.121094 
	C390.547974,455.288300 395.887695,454.743317 400.835571,451.835999 
	C400.925507,451.925598 401.105377,452.104858 401.105377,452.104858 
	C398.805328,456.006622 395.006958,456.788788 390.944550,456.877960 
	C387.158997,456.961060 383.370178,456.895721 379.525787,457.590149 
	C384.174713,460.209351 390.650757,459.101776 392.441406,466.013489 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M256.918488,616.047913 
	C258.231537,618.846741 259.627594,621.610596 260.825195,624.457947 
	C261.499084,626.060181 261.845459,627.800171 262.615570,629.705322 
	C263.015228,630.610046 263.138672,631.287415 263.179504,632.709290 
	C263.988220,635.941223 264.879578,638.428650 265.770935,640.916138 
	C265.364349,645.155273 264.957733,649.394409 263.804932,653.863708 
	C259.674774,654.432373 256.290771,654.770813 252.906769,655.109314 
	C245.714401,653.712646 243.122299,648.405457 241.877411,641.383545 
	C241.880432,640.266113 241.793716,639.794312 241.707031,639.322510 
	C241.427399,638.901306 241.147781,638.480103 240.828293,637.675903 
	C240.993637,636.035034 241.198853,634.777161 241.718918,633.577026 
	C242.999802,635.104126 243.965820,636.573547 244.994415,638.422852 
	C245.750534,641.519165 246.284470,644.289246 247.175400,646.939331 
	C248.481201,650.823486 251.609451,652.265564 255.378860,651.662048 
	C259.148529,651.058411 263.969635,650.926819 262.963379,644.949158 
	C262.230133,640.593506 261.018677,636.318359 260.021423,631.592346 
	C258.987823,626.134216 257.953156,621.091064 256.918488,616.047913 
z"/>
<path fill="#2263AA" opacity="1.000000" stroke="none" 
	d="
M448.517761,708.508911 
	C446.354462,713.808899 443.139923,715.667542 438.873749,714.184875 
	C434.715027,712.739563 433.595642,709.449036 434.185883,705.429626 
	C434.748932,701.595581 438.056641,699.214783 442.203339,699.754944 
	C447.045868,700.385681 448.964020,703.430908 448.517761,708.508911 
z"/>
<path fill="#2263AA" opacity="1.000000" stroke="none" 
	d="
M487.372070,714.361267 
	C483.566284,714.820007 480.175232,714.092468 479.259644,710.586548 
	C478.587982,708.014648 478.744324,704.320129 480.199799,702.372559 
	C481.470459,700.672180 485.460541,699.578003 487.537628,700.290405 
	C489.806976,701.068787 492.164948,703.973328 492.858093,706.413208 
	C493.941864,710.228149 491.468018,712.965942 487.372070,714.361267 
z"/>
<path fill="#2263AA" opacity="1.000000" stroke="none" 
	d="
M567.578491,704.755371 
	C570.441711,699.978577 574.184875,698.449341 577.986633,700.437378 
	C581.793213,702.427979 582.864990,705.751526 581.589111,709.718811 
	C580.212952,713.998291 576.740601,715.133484 572.842651,714.289001 
	C568.040222,713.248535 566.858032,709.590820 567.578491,704.755371 
z"/>
<path fill="#2263AA" opacity="1.000000" stroke="none" 
	d="
M537.090088,704.073242 
	C538.194214,709.514587 536.731140,713.100769 532.872620,714.149658 
	C528.975891,715.208740 525.567505,714.347229 523.634949,710.391907 
	C521.942200,706.927551 523.200684,702.487366 526.253906,700.709473 
	C529.841492,698.620239 533.745117,699.710632 537.090088,704.073242 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M257.087158,577.706543 
	C256.618134,574.403809 256.265808,570.811279 256.330963,567.226318 
	C256.402863,563.267883 257.035248,559.319641 257.106232,555.361267 
	C257.230835,548.413757 255.639435,546.811707 248.786621,547.050598 
	C247.615875,547.091431 245.866699,548.205383 245.440109,549.253235 
	C239.839691,563.010315 235.911407,577.064636 238.989746,592.564941 
	C238.039795,591.623535 236.332077,590.315552 236.228470,588.890869 
	C235.226578,575.115479 238.176605,561.976624 242.978836,549.137634 
	C245.161163,543.303040 249.845200,544.853149 253.870880,545.349792 
	C257.836884,545.839050 259.141418,549.054932 258.940094,552.736206 
	C258.489868,560.967834 257.913147,569.192505 257.087158,577.706543 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M355.974426,453.026306 
	C355.394531,451.584412 354.508636,450.049805 354.499512,448.509979 
	C354.440430,438.552124 359.882385,434.079376 369.398773,432.257660 
	C379.435577,430.336334 389.201263,426.740631 399.623016,428.855865 
	C405.849854,430.119659 408.140717,432.935272 406.660553,438.988037 
	C405.586639,443.379486 406.687439,448.870972 401.446960,452.000854 
	C401.105377,452.104858 400.925507,451.925598 400.961578,451.504333 
	C402.328949,446.529053 403.924927,442.031342 404.909851,437.403625 
	C405.735046,433.526703 403.267426,431.340546 399.668091,431.104553 
	C394.929535,430.793884 390.030304,430.369598 385.421722,431.209717 
	C377.482574,432.656921 369.648102,434.825653 361.922821,437.191467 
	C360.072449,437.758148 358.014709,440.062012 357.476013,441.969025 
	C356.506958,445.399323 356.614990,449.133850 356.273041,452.741272 
	C356.273041,452.741272 356.073914,452.931213 355.974426,453.026306 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M328.936859,478.946472 
	C321.024933,484.670502 308.657471,484.653259 300.668518,478.783478 
	C299.891998,478.212952 298.991028,476.357819 299.233093,476.093689 
	C300.682373,474.512390 302.449707,473.222534 304.525391,472.261719 
	C305.066803,479.465851 305.474548,478.793945 311.858704,480.222809 
	C320.142120,482.076782 325.591339,479.907593 329.163666,472.410309 
	C329.299683,472.124908 329.623077,471.933502 329.837616,471.680084 
	C333.265350,467.631348 332.838135,466.220581 327.692444,464.581512 
	C329.162109,464.044037 330.623108,463.112854 332.103912,463.080170 
	C333.574432,463.047699 335.797882,463.516785 336.341553,464.506927 
	C336.891266,465.508118 336.008240,467.523590 335.351929,468.904144 
	C334.452087,470.797089 333.174225,472.510315 332.040405,474.657623 
	C332.022400,475.014679 331.826172,475.374054 331.442749,475.663513 
	C330.353577,476.945923 329.647827,477.938843 328.942108,478.931763 
	C328.942108,478.931763 328.944763,478.947449 328.936859,478.946472 
z"/>
<path fill="#446993" opacity="1.000000" stroke="none" 
	d="
M625.275269,702.801025 
	C625.712769,703.723572 626.058777,704.316101 626.177979,704.951111 
	C626.926147,708.938049 626.072449,712.451843 622.031189,714.010132 
	C618.565491,715.346497 614.941345,715.000061 612.841736,711.406677 
	C610.949646,708.168579 610.802734,704.532959 613.671448,701.767029 
	C616.833069,698.718750 620.536804,698.918640 624.103333,701.378052 
	C624.497070,701.649536 624.756958,702.115051 625.275269,702.801025 
M623.887451,709.042542 
	C624.665710,706.037781 623.760620,703.566284 620.834595,702.607178 
	C619.402954,702.137939 617.100586,702.390076 615.986145,703.294250 
	C613.725220,705.128662 612.948181,707.657898 615.530029,710.139954 
	C618.353333,712.854187 621.024109,712.973145 623.887451,709.042542 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M257.716736,517.102478 
	C256.636200,520.770508 254.909470,524.387939 253.889603,528.194702 
	C253.442810,529.862305 254.439545,531.916687 254.780777,533.795410 
	C256.780182,533.039856 259.473145,532.846069 260.634827,531.411926 
	C263.063995,528.412903 264.685699,524.759705 266.639069,521.375183 
	C267.095215,521.455261 267.551361,521.535339 268.007507,521.615417 
	C268.007507,524.113403 268.007507,526.611328 267.981659,529.542847 
	C267.086548,529.582581 266.217316,529.188782 265.407196,528.821777 
	C263.545502,530.998413 261.897461,533.577759 259.641876,535.408508 
	C256.307129,538.115295 250.686798,535.706787 251.458481,531.706604 
	C252.432816,526.655945 255.304504,521.971313 257.716736,517.102478 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M338.061493,495.299255 
	C342.294647,497.195190 346.407471,499.707336 350.768951,501.665680 
	C361.699890,506.573822 365.406982,505.771118 374.562683,497.061829 
	C373.730560,502.365326 365.270386,508.458771 359.264984,506.595428 
	C352.029327,504.350372 345.335754,500.351654 338.437531,497.049438 
	C338.153687,496.913605 338.175140,496.139954 338.061493,495.299255 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M335.455658,458.433655 
	C333.640930,458.975250 332.129303,459.350555 330.370605,459.375885 
	C331.675751,458.202423 333.281769,457.464905 334.767365,456.535065 
	C336.943970,455.172699 339.221893,453.873932 341.079498,452.143219 
	C341.959198,451.323608 341.964844,449.565796 342.366516,448.233032 
	C340.881256,448.027344 339.396637,447.663818 337.910675,447.658325 
	C336.269196,447.652252 334.626495,447.974304 333.131531,447.899780 
	C333.853455,447.130585 334.428192,446.615112 335.002899,446.099609 
	C336.067352,445.717316 337.131805,445.335022 338.885132,444.919250 
	C341.690491,445.256287 343.806976,445.626862 345.923431,445.997406 
	C345.908722,446.751495 345.894012,447.505554 345.594421,448.847412 
	C345.183655,450.266907 345.057709,451.098694 344.931732,451.930450 
	C344.931732,451.930450 344.786438,452.338501 344.439697,452.642639 
	C343.681030,453.574341 343.269104,454.201904 342.857178,454.829468 
	C341.795685,455.522949 340.734192,456.216431 339.077515,456.950500 
	C337.990997,457.228210 337.499664,457.465332 337.008362,457.702454 
	C336.591827,457.890717 336.175293,458.079010 335.455658,458.433655 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M311.880463,465.125427 
	C309.247284,466.432556 306.614105,467.739655 303.092316,469.487885 
	C303.582642,467.615540 303.807922,466.755310 304.109009,465.585449 
	C304.953339,464.600128 305.721893,463.924438 307.108582,463.136230 
	C308.578094,462.155518 309.429443,461.287323 310.280823,460.419128 
	C310.280823,460.419128 310.702698,460.269623 311.232483,460.165100 
	C312.873322,459.096405 313.984375,458.132263 315.095428,457.168121 
	C315.095428,457.168121 315.115906,457.124908 315.480469,457.080566 
	C317.561310,456.355988 319.277618,455.675751 320.954254,455.334595 
	C317.903198,458.824249 314.891846,461.974823 311.880463,465.125427 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M326.285675,608.712463 
	C324.677246,607.039062 323.186249,605.032471 321.348816,602.660889 
	C320.983490,601.915527 320.964569,601.535217 320.886658,600.959229 
	C320.542633,600.854614 320.257599,600.945618 319.747742,601.244385 
	C317.695709,600.264648 315.868561,599.076965 314.047913,597.482544 
	C314.033386,595.719727 314.012299,594.363708 313.991241,593.007690 
	C313.991241,593.007690 313.998291,593.001465 314.331421,593.078125 
	C315.719849,594.675964 316.775177,596.197205 318.625244,598.864136 
	C318.385315,596.261414 318.272522,595.037659 318.159729,593.813904 
	C320.907532,598.669067 323.655334,603.524170 326.285675,608.712463 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M329.290283,593.320068 
	C330.573090,594.193054 331.993561,595.289185 333.082703,596.650452 
	C338.307495,603.180847 344.548798,604.949097 352.494232,600.987549 
	C349.269623,604.687073 343.895142,606.353882 339.593567,603.614746 
	C335.643860,601.099670 332.675781,597.042969 329.290283,593.320068 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M274.238525,641.314270 
	C275.281036,641.657288 276.530182,642.168457 277.440430,643.035461 
	C281.312469,646.723511 285.483582,646.964600 290.118286,644.616150 
	C292.749939,643.282776 295.522919,642.228394 298.598572,641.031433 
	C289.359985,649.525513 278.731079,650.291077 274.238525,641.314270 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M360.239563,595.439209 
	C366.269958,589.268555 372.616394,583.171204 379.267242,577.016113 
	C378.242096,578.732788 377.140045,580.752502 375.540527,582.235657 
	C370.648224,586.772034 365.564697,591.102173 360.239563,595.439209 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M239.057816,593.366943 
	C239.650940,593.156677 240.291458,593.300415 240.921890,593.479492 
	C250.518341,596.205872 252.822296,594.945923 255.877121,585.228638 
	C256.864288,589.561462 255.699631,593.490784 251.671738,595.962708 
	C248.229462,598.075256 242.221191,596.810913 239.057816,593.366943 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M320.993927,454.995514 
	C319.277618,455.675751 317.561310,456.355988 315.504028,457.084747 
	C319.969818,453.776978 324.710815,450.319244 329.625336,447.128906 
	C331.024567,446.220612 332.928467,446.089722 334.801941,445.851013 
	C334.428192,446.615112 333.853455,447.130585 332.813965,447.929565 
	C331.575897,448.574463 330.802643,448.935791 329.812622,449.318298 
	C329.304230,449.477081 329.146088,449.706726 328.916046,450.254272 
	C327.840942,451.014587 326.971161,451.548950 325.752594,452.092316 
	C323.934937,453.065460 322.466095,454.029602 320.998627,454.996887 
	C321.000000,455.000000 320.993927,454.995514 320.993927,454.995514 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M311.129578,699.395142 
	C316.598511,698.033020 322.249329,696.981262 328.257812,695.904236 
	C323.554169,700.350220 317.786407,701.626709 311.129578,699.395142 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M375.241699,496.860168 
	C377.956085,492.575836 381.020996,488.283905 384.393921,483.884064 
	C381.665375,488.134979 378.628784,492.493774 375.241699,496.860168 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M301.221924,640.328613 
	C304.606018,637.794312 308.313843,635.378479 312.367401,632.958801 
	C308.990662,635.452332 305.268188,637.949707 301.221924,640.328613 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M336.799744,481.217682 
	C335.766144,485.186005 333.356262,489.150391 336.989624,493.590820 
	C331.837952,491.151276 331.623413,486.497284 336.799744,481.217682 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M344.712036,470.097260 
	C342.656738,473.701538 340.255890,477.339783 337.533386,481.062531 
	C339.596649,477.475098 341.981537,473.803162 344.712036,470.097260 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M364.107544,662.694824 
	C362.295776,659.900452 360.677490,656.860474 359.021576,653.477905 
	C360.756287,656.239990 362.528687,659.344604 364.107544,662.694824 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M253.067291,655.433105 
	C256.290771,654.770813 259.674774,654.432373 263.451721,654.048340 
	C260.910217,658.246277 256.994965,656.553467 253.067291,655.433105 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M299.603058,691.268066 
	C301.411438,692.560120 303.161041,694.155640 304.980072,696.029785 
	C303.214417,694.729492 301.379364,693.150513 299.603058,691.268066 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M265.980835,640.697632 
	C264.879578,638.428650 263.988220,635.941223 263.160645,633.054077 
	C264.213165,635.262695 265.201935,637.870850 265.980835,640.697632 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M387.347229,479.951538 
	C388.356171,477.666870 389.710999,475.341187 391.368713,472.893341 
	C390.345398,475.150970 389.019226,477.530762 387.347229,479.951538 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M346.167297,445.807220 
	C343.806976,445.626862 341.690491,445.256287 339.214539,444.830261 
	C342.737823,441.338959 344.591278,441.545532 346.167297,445.807220 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M318.107666,593.573608 
	C318.272522,595.037659 318.385315,596.261414 318.625244,598.864136 
	C316.775177,596.197205 315.719849,594.675964 314.333862,593.075989 
	C314.750916,589.596741 316.067078,592.009094 317.476685,592.819702 
	C317.740112,592.955994 318.055634,593.333252 318.107666,593.573608 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M354.276367,600.467651 
	C355.220062,599.238525 356.481476,598.089905 358.033234,596.908325 
	C357.080414,598.099670 355.837250,599.323914 354.276367,600.467651 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M339.431824,689.775696 
	C340.813385,688.527588 342.526215,687.291077 344.573792,686.032104 
	C343.193390,687.268860 341.478210,688.528076 339.431824,689.775696 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M295.388306,685.284668 
	C296.614258,686.368652 297.814972,687.777100 299.005493,689.526428 
	C297.784546,688.447937 296.573822,687.028503 295.388306,685.284668 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M489.114990,493.326843 
	C490.675354,494.016510 492.288086,495.031097 493.970154,496.350494 
	C492.415466,495.654144 490.791382,494.652954 489.114990,493.326843 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M381.253906,575.542114 
	C382.021057,574.342468 383.105286,573.184998 384.503662,571.979126 
	C383.735535,573.148621 382.653259,574.366455 381.253906,575.542114 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M517.838989,480.640381 
	C517.394165,479.350006 517.236450,477.890442 517.162903,476.043732 
	C517.540100,477.261475 517.833130,478.866333 517.838989,480.640381 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M314.730377,457.129028 
	C313.984375,458.132263 312.873322,459.096405 311.439758,460.080811 
	C312.199982,459.097351 313.282654,458.093658 314.730377,457.129028 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M329.292297,478.909515 
	C329.647827,477.938843 330.353577,476.945923 331.356628,475.849304 
	C330.983459,476.792816 330.312958,477.840027 329.292297,478.909515 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M309.929993,460.329773 
	C309.429443,461.287323 308.578094,462.155518 307.403412,463.044098 
	C307.913147,462.123169 308.746185,461.181824 309.929993,460.329773 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M327.014526,613.708618 
	C326.595825,613.121338 326.403229,612.363037 326.369568,611.409546 
	C326.765869,611.988770 327.003235,612.763245 327.014526,613.708618 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M363.342896,669.961243 
	C363.202881,669.194214 363.319275,668.335144 363.688660,667.264526 
	C363.827545,667.991699 363.713470,668.930481 363.342896,669.961243 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M497.369690,499.209869 
	C498.191589,499.193787 499.020355,499.493225 499.953979,500.036316 
	C499.164734,500.028442 498.270630,499.776917 497.369690,499.209869 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M345.250366,451.882812 
	C345.057709,451.098694 345.183655,450.266907 345.542145,449.220978 
	C345.706116,449.949585 345.637543,450.892365 345.250366,451.882812 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M329.281921,695.596436 
	C329.671478,695.033508 330.366394,694.516602 331.319489,693.952515 
	C330.914215,694.484375 330.250763,695.063416 329.281921,695.596436 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M292.561981,481.935120 
	C292.222198,482.629852 291.533417,483.340454 290.544861,484.118195 
	C290.901062,483.440552 291.557037,482.695801 292.561981,481.935120 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M480.871460,487.451080 
	C481.481873,487.520416 482.191772,487.889313 482.962830,488.581940 
	C482.339630,488.520660 481.655273,488.135651 480.871460,487.451080 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M516.491394,487.145660 
	C516.344666,486.623108 516.453186,485.951874 516.762817,485.122314 
	C516.891541,485.641632 516.819092,486.319305 516.491394,487.145660 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M483.172424,489.252319 
	C483.711639,489.282440 484.308502,489.601593 484.985413,490.169434 
	C484.453674,490.125885 483.841858,489.833588 483.172424,489.252319 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M385.324341,482.923370 
	C385.376007,482.310516 385.748505,481.640167 386.413483,480.880402 
	C386.352386,481.482605 385.998749,482.174225 385.324341,482.923370 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M268.812195,500.172546 
	C268.746918,500.803528 268.338287,501.430573 267.661041,502.167358 
	C267.751221,501.574249 268.110016,500.871429 268.812195,500.172546 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M262.726624,509.095642 
	C262.680054,509.715271 262.301819,510.373016 261.648956,511.142151 
	C261.714539,510.546967 262.054749,509.840393 262.726624,509.095642 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M357.501099,650.837646 
	C356.988281,650.663879 356.496307,650.203186 355.961121,649.476440 
	C356.452606,649.657166 356.987274,650.103943 357.501099,650.837646 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M306.316101,697.193115 
	C306.768372,697.127747 307.271301,697.320496 307.882507,697.679565 
	C307.449432,697.714355 306.908112,697.582764 306.316101,697.193115 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M264.664429,506.046967 
	C264.632599,506.672485 264.272705,507.354034 263.627838,508.142822 
	C263.674042,507.534393 264.005219,506.818695 264.664429,506.046967 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M512.625977,466.779785 
	C512.065613,466.662659 511.543762,466.256348 510.987305,465.587646 
	C511.523315,465.713715 512.093933,466.102142 512.625977,466.779785 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M335.213135,692.201294 
	C335.439331,691.721252 335.900391,691.367859 336.595062,691.020630 
	C336.368439,691.460510 335.908234,691.894287 335.213135,692.201294 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M337.129120,457.965118 
	C337.499664,457.465332 337.990997,457.228210 338.770020,457.031921 
	C338.455078,457.457764 337.852478,457.842773 337.129120,457.965118 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M266.720886,503.083740 
	C266.655945,503.709961 266.259247,504.371704 265.570648,505.127136 
	C265.648834,504.520294 266.018951,503.819763 266.720886,503.083740 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M348.206207,684.166626 
	C348.400543,683.687439 348.824280,683.335388 349.467041,682.963013 
	C349.269257,683.393127 348.852448,683.843506 348.206207,684.166626 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M351.198059,682.214294 
	C351.411224,681.724121 351.857239,681.358154 352.537476,680.976440 
	C352.324768,681.419922 351.877808,681.879211 351.198059,682.214294 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M354.204651,680.165283 
	C354.391815,679.680664 354.805878,679.325745 355.436096,678.942017 
	C355.245392,679.373718 354.838501,679.834351 354.204651,680.165283 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M241.441299,639.317871 
	C241.793716,639.794312 241.880432,640.266113 241.900406,640.990662 
	C241.614304,640.600037 241.394943,639.956665 241.441299,639.317871 
z"/>
<path fill="#A5D1D9" opacity="1.000000" stroke="none" 
	d="
M343.195984,454.828247 
	C343.269104,454.201904 343.681030,453.574341 344.369324,452.847412 
	C344.275391,453.441101 343.905090,454.134064 343.195984,454.828247 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M338.332581,690.530029 
	C338.184753,690.453308 338.506104,690.114197 338.623993,690.098389 
	C338.842804,690.351685 338.755646,690.526428 338.332581,690.530029 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M495.693542,498.147217 
	C495.787445,498.112976 495.778595,498.134796 495.779114,498.118835 
	C495.779633,498.102875 495.599670,498.181427 495.693542,498.147217 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M497.903259,613.091431 
	C495.225677,613.262390 493.581696,613.118164 493.898834,610.019653 
	C495.131561,597.976379 496.180542,585.914246 497.328674,573.862122 
	C497.348419,573.655212 497.754272,573.485046 498.853577,572.572815 
	C500.202026,586.193298 501.488892,599.191650 502.827820,612.715942 
	C501.188202,612.858765 499.776825,612.981689 497.903259,613.091431 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M428.149048,581.080933 
	C428.149048,578.292664 428.149048,575.991516 428.149048,573.779053 
	C436.463043,571.514832 439.759155,573.772400 440.034363,581.858948 
	C440.192352,586.500671 440.241180,591.166138 439.963684,595.798401 
	C439.478760,603.892822 436.902405,605.743591 428.149048,604.243958 
	C428.149048,596.758911 428.149048,589.163452 428.149048,581.080933 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M668.665283,703.990234 
	C668.662842,702.501221 668.458008,701.452148 668.699097,700.518188 
	C669.308350,698.157471 666.741455,694.496887 671.247375,693.691345 
	C675.726196,692.890747 679.838440,695.168457 681.327332,699.072205 
	C684.755615,708.060974 679.608887,713.964539 668.665894,712.614502 
	C668.665894,709.984741 668.665894,707.233704 668.665283,703.990234 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M741.744507,701.280151 
	C742.542786,703.466797 743.223389,705.296631 744.089966,707.626587 
	C741.091736,707.626587 738.707703,707.626587 735.592773,707.626587 
	C736.969543,703.996460 738.208435,700.730042 739.887390,696.303162 
	C740.758606,698.617249 741.192749,699.770325 741.744507,701.280151 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M856.417480,707.915283 
	C855.404907,707.060547 854.092834,706.007935 854.267944,705.353027 
	C854.989319,702.655762 856.162170,700.079346 857.593994,696.352478 
	C859.198303,700.475037 860.444153,703.676392 862.087891,707.900208 
	C859.856995,707.900208 858.329346,707.900208 856.417480,707.915283 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M346.902496,514.657898 
	C348.679382,523.163086 343.129150,527.601746 338.206238,532.308289 
	C333.042023,537.245483 327.715729,542.014404 322.590332,546.990845 
	C315.275177,554.093323 308.477417,552.667114 305.247467,542.992310 
	C304.059998,539.435425 303.554901,535.621155 303.015564,531.884399 
	C302.205353,526.270813 303.576904,521.708374 308.528381,518.000427 
	C312.596558,514.953979 315.769867,510.723267 319.396576,507.073883 
	C323.337128,503.108643 327.453094,502.101807 332.420563,504.819916 
	C337.440796,507.566864 342.044067,511.075806 346.902496,514.657898 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M435.972290,435.989685 
	C443.246490,437.710785 450.184082,439.118347 456.892242,441.248413 
	C461.449524,442.695496 461.929443,447.135254 461.700806,451.170898 
	C461.544250,453.934113 460.595734,456.690491 459.744843,459.368439 
	C457.957153,464.994568 451.416168,467.933624 445.863342,466.025177 
	C440.415283,464.152710 434.681030,463.015137 429.405060,460.772339 
	C425.535980,459.127625 421.972504,456.495117 418.719849,453.769409 
	C416.746063,452.115387 421.289581,434.454498 423.785614,433.791748 
	C425.509247,433.334106 427.581573,433.973389 429.453125,434.347504 
	C431.508301,434.758392 433.511902,435.427399 435.972290,435.989685 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M268.003418,550.935913 
	C268.889038,545.758484 271.552490,543.212036 276.452515,542.096558 
	C279.160156,541.480225 281.557739,539.230103 283.933685,537.495361 
	C287.076172,535.200989 289.429871,535.668213 290.928070,539.299622 
	C292.991119,544.300171 295.066498,549.303955 296.876343,554.398804 
	C299.360046,561.390381 297.036316,567.096375 290.298645,571.507385 
	C284.446075,575.339050 278.806335,579.531921 272.752838,583.008484 
	C268.453522,585.477722 264.417847,583.366455 264.196411,578.437195 
	C263.782898,569.232483 262.685150,559.797852 268.003418,550.935913 
z"/>
<path fill="#266FB0" opacity="1.000000" stroke="none" 
	d="
M275.603699,698.399902 
	C267.868958,690.624512 261.996124,682.046875 258.600098,671.892639 
	C264.018097,668.914917 272.868530,670.621643 276.528656,675.984497 
	C281.078461,682.650940 285.257355,689.581238 289.349854,696.542603 
	C290.641602,698.739929 291.140930,701.403076 291.992828,703.824341 
	C284.286652,707.145569 282.753052,706.620544 275.603699,698.399902 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M327.285522,464.613342 
	C332.838135,466.220581 333.265350,467.631348 329.837616,471.680084 
	C329.623077,471.933502 329.299683,472.124908 329.163666,472.410309 
	C325.591339,479.907593 320.142120,482.076782 311.858704,480.222809 
	C305.474548,478.793945 305.066803,479.465851 304.895569,472.233276 
	C312.193420,469.400452 319.536011,467.022827 327.285522,464.613342 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M330.029388,449.297119 
	C330.802643,448.935791 331.575897,448.574463 332.666748,448.183289 
	C334.626495,447.974304 336.269196,447.652252 337.910675,447.658325 
	C339.396637,447.663818 340.881256,448.027344 342.366516,448.233032 
	C341.964844,449.565796 341.959198,451.323608 341.079498,452.143219 
	C339.221893,453.873932 336.943970,455.172699 334.767365,456.535065 
	C333.281769,457.464905 331.675751,458.202423 330.021759,459.336731 
	C324.166840,461.451782 318.413696,463.255951 312.270508,465.092773 
	C314.891846,461.974823 317.903198,458.824249 320.954254,455.334595 
	C320.993927,454.995514 321.000000,455.000000 321.364746,455.007050 
	C323.186798,454.037140 324.644073,453.060211 326.101379,452.083313 
	C326.971161,451.548950 327.840942,451.014587 329.096283,450.171814 
	C329.710205,449.719025 329.892731,449.530243 330.029388,449.297119 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M276.003448,504.047791 
	C277.318451,501.314850 278.473755,498.928467 279.734833,496.323669 
	C283.666626,496.033447 285.440613,497.693542 285.041809,501.799774 
	C284.641998,505.916382 284.366394,510.048859 284.174133,514.180847 
	C283.928558,519.459106 280.039886,521.978333 276.240723,524.230225 
	C272.784119,526.279053 270.463684,524.887939 271.160004,520.953552 
	C272.154663,515.333557 274.229980,509.904755 276.003448,504.047791 
z"/>
<path fill="#1D62AA" opacity="1.000000" stroke="none" 
	d="
M305.869568,729.257141 
	C304.243011,727.793945 302.793701,726.649658 301.611237,725.275635 
	C300.977722,724.539490 300.235535,723.059692 300.556030,722.537109 
	C301.029724,721.764587 302.459930,721.076904 303.387146,721.183716 
	C305.652863,721.444824 308.203003,721.652771 310.034271,722.825684 
	C316.000580,726.646973 321.728851,730.845520 327.482361,734.991028 
	C330.335175,737.046692 333.042694,739.303894 336.038666,741.643860 
	C332.965820,745.042603 330.430481,743.869934 327.732117,742.245789 
	C320.573151,737.936646 313.353394,733.728638 305.869568,729.257141 
z"/>
<path fill="#2D94C3" opacity="1.000000" stroke="none" 
	d="
M328.996033,586.019714 
	C324.280731,580.562866 325.224945,574.178711 326.025879,567.406494 
	C326.662476,566.795593 327.512390,566.793640 327.878296,566.371826 
	C335.914520,557.107544 346.611725,550.818054 354.484222,541.091553 
	C361.725220,532.145264 370.891907,529.596130 381.906372,541.988403 
	C383.394196,543.432373 384.694336,544.214844 385.994507,544.997375 
	C385.994507,544.997375 386.027222,545.469604 386.009827,546.107788 
	C385.385803,551.190430 382.218414,549.749512 379.490295,549.739197 
	C377.749084,549.732605 375.160370,549.789185 374.401611,550.852356 
	C369.893158,557.169189 365.770111,563.675842 356.264160,561.064026 
	C355.430511,560.834961 354.387787,561.366821 353.524719,561.529663 
	C352.038055,569.841553 347.415741,575.246216 339.269135,576.237244 
	C332.926727,577.008728 331.158661,581.455017 328.996033,586.019714 
z"/>
<path fill="#2C8EBF" opacity="1.000000" stroke="none" 
	d="
M385.866272,544.698120 
	C384.694336,544.214844 383.394196,543.432373 382.059174,542.287109 
	C382.756195,541.932983 383.488098,541.941650 384.584778,541.941162 
	C385.212372,542.754333 385.475220,543.576660 385.866272,544.698120 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M343.601501,478.637146 
	C346.519867,474.573822 348.641968,470.059631 352.143372,467.287781 
	C355.231140,464.843445 359.800995,463.451294 363.819824,463.190826 
	C370.217926,462.776154 376.721069,463.490356 383.142700,464.110931 
	C387.171906,464.500336 389.316376,469.336884 387.383209,472.914307 
	C387.073761,473.486938 386.501099,473.910645 386.126923,474.455902 
	C381.260284,481.547028 376.369965,488.622406 371.565216,495.755402 
	C368.615936,500.133728 358.935089,502.871033 353.897125,500.189087 
	C349.170898,497.673096 344.595459,494.784332 340.320831,491.571136 
	C339.222748,490.745758 338.813354,487.853485 339.314362,486.308533 
	C340.164429,483.687103 341.989594,481.381927 343.601501,478.637146 
z"/>
<path fill="#2263AA" opacity="1.000000" stroke="none" 
	d="
M350.984161,648.995239 
	C354.054749,653.593628 357.265015,658.108704 360.092346,662.852051 
	C360.753845,663.961853 360.751434,666.423279 359.973236,667.275635 
	C355.813904,671.831116 351.760010,676.691223 346.820435,680.275879 
	C339.865448,685.323120 332.161255,689.336670 324.786377,693.806885 
	C317.501129,698.222656 309.492615,696.612061 304.295685,689.740479 
	C303.148224,688.223328 301.771851,686.879272 300.251740,684.834290 
	C300.035339,681.130127 301.101654,680.362549 304.029266,681.941895 
	C307.520752,683.825439 311.112488,682.472534 310.716034,679.434509 
	C309.966217,673.688477 313.588806,674.051575 317.405975,672.745972 
	C322.454285,671.019287 326.993774,667.654236 331.539673,664.669983 
	C333.988525,663.062378 335.697357,660.152710 338.264191,658.937378 
	C343.080505,656.657104 349.396454,656.578552 350.075134,649.293335 
	C350.086914,649.166870 350.668488,649.093445 350.984161,648.995239 
z"/>
<path fill="#266FB0" opacity="1.000000" stroke="none" 
	d="
M350.839783,648.696655 
	C350.668488,649.093445 350.086914,649.166870 350.075134,649.293335 
	C349.396454,656.578552 343.080505,656.657104 338.264191,658.937378 
	C335.697357,660.152710 333.988525,663.062378 331.539673,664.669983 
	C326.993774,667.654236 322.454285,671.019287 317.405975,672.745972 
	C313.588806,674.051575 309.966217,673.688477 310.716034,679.434509 
	C311.112488,682.472534 307.520752,683.825439 304.029266,681.941895 
	C301.101654,680.362549 300.035339,681.130127 299.997742,684.604736 
	C297.935333,682.982910 295.877838,680.969482 293.475891,678.903259 
	C293.131409,678.850464 293.071136,678.461792 293.050476,677.872131 
	C293.017914,676.498657 293.005981,675.714905 293.205688,674.769592 
	C292.957703,674.084900 292.498047,673.561707 292.020264,673.018188 
	C292.002136,672.997925 291.960846,672.962524 291.960846,672.962524 
	C290.256561,663.060547 298.534729,660.773560 304.440887,656.816406 
	C312.204590,651.614746 320.191986,646.747620 327.976868,641.576660 
	C332.304626,638.702148 336.712799,639.486145 341.582703,640.769653 
	C344.852905,643.409607 347.774170,645.903870 350.839783,648.696655 
z"/>
<path fill="#446993" opacity="1.000000" stroke="none" 
	d="
M292.994019,674.931091 
	C293.005981,675.714905 293.017914,676.498657 293.030273,677.676514 
	C292.468475,676.404663 291.906281,674.738708 291.652466,673.017700 
	C291.960846,672.962524 292.002136,672.997925 292.044983,673.297913 
	C292.389893,674.042297 292.691956,674.486694 292.994019,674.931091 
z"/>
<path fill="#446993" opacity="1.000000" stroke="none" 
	d="
M337.698364,637.522034 
	C338.027802,637.004028 338.563995,636.694702 339.100220,636.385376 
	C339.223511,636.651917 339.346863,636.918396 339.470215,637.184937 
	C338.948517,637.366882 338.426819,637.548767 337.698364,637.522034 
z"/>
<path fill="#2C8EBF" opacity="1.000000" stroke="none" 
	d="
M329.148193,586.322144 
	C331.158661,581.455017 332.926727,577.008728 339.269135,576.237244 
	C347.415741,575.246216 352.038055,569.841553 353.524719,561.529663 
	C354.387787,561.366821 355.430511,560.834961 356.264160,561.064026 
	C365.770111,563.675842 369.893158,557.169189 374.401611,550.852356 
	C375.160370,549.789185 377.749084,549.732605 379.490295,549.739197 
	C382.218414,549.749512 385.385803,551.190430 386.014191,546.344421 
	C390.602997,547.485718 393.151215,551.908813 391.232025,556.325195 
	C389.629425,560.012878 387.568787,563.847900 384.717194,566.580566 
	C376.675964,574.286255 368.107483,581.440491 359.779236,588.848572 
	C356.235504,592.000671 352.883667,595.377625 349.238190,598.403625 
	C346.194763,600.929932 342.566101,601.059937 339.740601,598.330200 
	C335.990814,594.707520 332.756287,590.551392 329.148193,586.322144 
z"/>
<path fill="#2D94C3" opacity="1.000000" stroke="none" 
	d="
M313.650024,592.921997 
	C314.012299,594.363708 314.033386,595.719727 314.033630,597.912292 
	C315.552368,605.853149 308.380280,609.402893 301.144592,612.934631 
	C298.771881,614.092773 295.577728,616.507141 295.374420,618.600891 
	C294.836975,624.134949 291.153900,624.626892 287.731262,623.792297 
	C283.093628,622.661560 280.701111,624.206055 278.265808,627.789062 
	C277.368530,629.109253 274.799225,629.293030 272.992584,629.995117 
	C272.473083,628.456970 271.953583,626.918823 271.163177,625.156006 
	C270.577850,621.992920 270.297699,619.050354 269.942444,616.116882 
	C268.949280,607.915283 273.372711,603.411011 280.187653,599.951904 
	C285.814178,597.096069 291.295868,593.777344 296.339752,589.994324 
	C303.301819,584.772705 308.225616,585.337708 313.650024,592.921997 
z"/>
<path fill="#2D94C3" opacity="1.000000" stroke="none" 
	d="
M501.589661,461.877625 
	C505.452698,465.346893 509.412354,468.450470 512.357544,472.325134 
	C515.288574,476.181152 515.706909,480.830200 512.897522,485.316315 
	C511.584442,487.413086 510.106964,489.508972 509.323975,491.815704 
	C507.283295,497.827545 502.284302,498.977875 497.292999,494.706573 
	C494.106750,491.979919 490.452393,489.796967 486.986603,487.401093 
	C483.901306,485.268219 480.786621,483.177795 477.338623,481.014282 
	C474.695892,474.247833 477.281250,467.763336 478.042023,461.224854 
	C479.018707,452.830566 484.533356,450.678894 491.233490,455.600769 
	C494.394135,457.922577 497.894989,459.781311 501.589661,461.877625 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M500.910095,457.119385 
	C499.843445,457.498718 498.759735,457.809174 497.676056,458.119629 
	C497.643188,457.543121 497.610291,456.966614 497.577423,456.390137 
	C498.434296,456.598755 499.291138,456.807373 500.453430,457.056519 
	C500.758881,457.097046 500.893005,457.050476 500.910095,457.119385 
z"/>
<path fill="#2C8EBF" opacity="1.000000" stroke="none" 
	d="
M273.033997,630.363770 
	C274.799225,629.293030 277.368530,629.109253 278.265808,627.789062 
	C280.701111,624.206055 283.093628,622.661560 287.731262,623.792297 
	C291.153900,624.626892 294.836975,624.134949 295.374420,618.600891 
	C295.577728,616.507141 298.771881,614.092773 301.144592,612.934631 
	C308.380280,609.402893 315.552368,605.853149 314.027100,598.319092 
	C315.868561,599.076965 317.695709,600.264648 319.992554,601.261841 
	C320.462219,601.071472 320.945648,601.154907 320.945648,601.154907 
	C320.964569,601.535217 320.983490,601.915527 321.016479,602.578369 
	C321.381042,604.774902 321.419098,606.816528 322.139374,608.579407 
	C324.514923,614.393921 321.644745,622.197571 316.168488,625.622681 
	C309.608704,629.725464 303.468994,634.510376 296.840668,638.488831 
	C293.389984,640.559937 289.381012,641.976929 285.438721,642.874573 
	C281.569183,643.755615 276.653076,639.669861 274.339203,634.509399 
	C273.798615,633.303894 273.490509,631.994202 273.033997,630.363770 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M241.985748,567.227722 
	C243.669601,562.197632 245.121567,557.487671 247.180389,553.059875 
	C247.709747,551.921387 250.177261,551.146423 251.702942,551.223511 
	C252.532745,551.265320 253.935699,553.458984 253.917496,554.664490 
	C253.767242,564.613586 253.606400,574.579407 252.817780,584.489929 
	C252.623657,586.929504 250.516144,589.621826 248.566559,591.412048 
	C246.245453,593.543396 241.459061,591.891296 241.210648,588.715088 
	C240.754242,582.879272 241.290085,576.963257 241.470108,571.082397 
	C241.505219,569.935059 241.803024,568.795837 241.985748,567.227722 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M294.306030,505.343445 
	C293.236938,501.521667 292.223785,498.077606 291.451569,494.580353 
	C290.418976,489.903748 297.243896,485.276794 302.648895,487.162933 
	C304.919983,487.955414 307.358459,489.149139 308.880798,490.893555 
	C309.878296,492.036621 310.238495,495.337341 309.385071,496.216400 
	C305.727386,499.984161 301.566803,503.279449 297.429474,506.544067 
	C296.958588,506.915619 295.480408,506.010590 294.306030,505.343445 
z"/>
<path fill="#2D94C3" opacity="1.000000" stroke="none" 
	d="
M244.931839,638.043030 
	C243.965820,636.573547 242.999802,635.104126 241.973267,633.297852 
	C241.038956,625.611938 240.165131,618.262756 239.164658,609.848267 
	C244.977020,612.692200 252.198349,608.945068 256.592529,615.965210 
	C257.953156,621.091064 258.987823,626.134216 259.658661,631.511169 
	C258.933655,631.524475 258.253326,631.197754 258.259430,630.884399 
	C258.345367,626.468689 254.746643,625.869446 251.891861,625.686035 
	C251.028183,625.630554 249.994064,629.475769 248.898010,631.456848 
	C247.658157,633.697693 246.260742,635.851440 244.931839,638.043030 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M356.618958,452.879333 
	C356.614990,449.133850 356.506958,445.399323 357.476013,441.969025 
	C358.014709,440.062012 360.072449,437.758148 361.922821,437.191467 
	C369.648102,434.825653 377.482574,432.656921 385.421722,431.209717 
	C390.030304,430.369598 394.929535,430.793884 399.668091,431.104553 
	C403.267426,431.340546 405.735046,433.526703 404.909851,437.403625 
	C403.924927,442.031342 402.328949,446.529053 400.871643,451.414734 
	C395.887695,454.743317 390.547974,455.288300 384.974487,455.121094 
	C378.008362,454.912140 371.027222,455.142456 364.066345,454.852386 
	C361.677887,454.752869 359.330872,453.657715 356.618958,452.879333 
M360.054504,449.350616 
	C360.922882,450.214600 361.780304,451.816895 362.661346,451.829987 
	C373.072266,451.984741 383.518280,452.378021 393.887421,451.689392 
	C399.431061,451.321198 403.333649,443.347992 401.917603,437.548126 
	C401.031647,433.919434 396.117065,432.010345 390.971588,433.146759 
	C386.149414,434.211761 381.291901,435.116608 376.450836,436.096252 
	C368.983765,437.607300 360.084229,437.225800 360.054504,449.350616 
z"/>
<path fill="#2C8EBF" opacity="1.000000" stroke="none" 
	d="
M244.994415,638.422852 
	C246.260742,635.851440 247.658157,633.697693 248.898010,631.456848 
	C249.994064,629.475769 251.028183,625.630554 251.891861,625.686035 
	C254.746643,625.869446 258.345367,626.468689 258.259430,630.884399 
	C258.253326,631.197754 258.933655,631.524475 259.657593,631.926147 
	C261.018677,636.318359 262.230133,640.593506 262.963379,644.949158 
	C263.969635,650.926819 259.148529,651.058411 255.378860,651.662048 
	C251.609451,652.265564 248.481201,650.823486 247.175400,646.939331 
	C246.284470,644.289246 245.750534,641.519165 244.994415,638.422852 
z"/>
<path fill="#1D62AA" opacity="1.000000" stroke="none" 
	d="
M623.727051,709.412720 
	C621.024109,712.973145 618.353333,712.854187 615.530029,710.139954 
	C612.948181,707.657898 613.725220,705.128662 615.986145,703.294250 
	C617.100586,702.390076 619.402954,702.137939 620.834595,702.607178 
	C623.760620,703.566284 624.665710,706.037781 623.727051,709.412720 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M320.886658,600.959229 
	C320.945648,601.154907 320.462219,601.071472 320.217407,601.054016 
	C320.257599,600.945618 320.542633,600.854614 320.886658,600.959229 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M325.752563,452.092285 
	C324.644073,453.060211 323.186798,454.037140 321.363373,455.003906 
	C322.466095,454.029602 323.934937,453.065460 325.752563,452.092285 
z"/>
<path fill="#5CABBB" opacity="1.000000" stroke="none" 
	d="
M329.812622,449.318298 
	C329.892731,449.530243 329.710205,449.719025 329.301636,449.945923 
	C329.146088,449.706726 329.304230,449.477081 329.812622,449.318298 
z"/>
<path fill="#4C85A7" opacity="1.000000" stroke="none" 
	d="
M293.205688,674.769592 
	C292.691956,674.486694 292.389893,674.042297 292.063110,673.318237 
	C292.498047,673.561707 292.957703,674.084900 293.205688,674.769592 
z"/>
<path fill="#34AECD" opacity="1.000000" stroke="none" 
	d="
M360.032776,448.935852 
	C360.084229,437.225800 368.983765,437.607300 376.450836,436.096252 
	C381.291901,435.116608 386.149414,434.211761 390.971588,433.146759 
	C396.117065,432.010345 401.031647,433.919434 401.917603,437.548126 
	C403.333649,443.347992 399.431061,451.321198 393.887421,451.689392 
	C383.518280,452.378021 373.072266,451.984741 362.661346,451.829987 
	C361.780304,451.816895 360.922882,450.214600 360.032776,448.935852 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
